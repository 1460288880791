import { action } from "typesafe-actions";

import { BookingsReportsActionTypes } from "@app/store/pages/reports/bookingsReports/bookingsReports.constants";

import { BookingReportsDto, ReportsSearchParams } from "@strafos/common";

export const getBookingsReportsAction = () =>
  action(BookingsReportsActionTypes.GetBookingsReports);

export const getBookingsReportsSuccessAction = (response: BookingReportsDto) =>
  action(BookingsReportsActionTypes.GetBookingsReportsSuccess, response);

export const getBookingsReportsFailureAction = (error: unknown) =>
  action(BookingsReportsActionTypes.GetBookingsReportsFailure, { error });

export const setBookingsReportsFiltersAction = (
  filters: Partial<ReportsSearchParams>,
  requestDebounceInMilliseconds = 0,
) =>
  action(BookingsReportsActionTypes.SetBookingsReportsFilters, {
    filters,
    requestDebounceInMilliseconds,
  });

export const resetBookingsReportsFiltersAction = () =>
  action(BookingsReportsActionTypes.ResetBookingsReportsFilters);
