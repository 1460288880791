import { ActionType } from "typesafe-actions";
import produce, { castDraft } from "immer";

import { AircraftDetailDto } from "@strafos/common";
import { AircraftListActionTypes } from "@app/store/pages/fleet/aircraftList/aircraftList.constants";
import { ListStore } from "@app/store/types";
import { GetAircraftListFilters } from "@app/utils/api/types";
import * as aircraftListActions from "@app/store/pages/fleet/aircraftList/aircraftList.actions";

import {
  DEFAULT_ORDER_BY,
  DEFAULT_ORDER_DIRECTION,
  DEFAULT_PAGE_LIMIT,
} from "@app/constants";

type AircraftListAction = ActionType<typeof aircraftListActions>;

export type AircraftListStore = ListStore<
  AircraftDetailDto,
  GetAircraftListFilters
>;

const initialAircraftListState: AircraftListStore = {
  error: null,
  isLoading: false,
  data: null,
  total: null,
  page: 1,
  limit: DEFAULT_PAGE_LIMIT,
  orderBy: DEFAULT_ORDER_BY,
  orderDirection: DEFAULT_ORDER_DIRECTION,
  filters: {},
};

const aircraftListReducer = produce<AircraftListStore, [AircraftListAction]>(
  (state, action) => {
    switch (action.type) {
      case AircraftListActionTypes.GetAircraftListDataFirstPage:
        state.isLoading = true;
        state.data = null;
        state.error = null;
        state.total = 0;
        state.limit = DEFAULT_PAGE_LIMIT;
        state.page = 1;

        break;

      case AircraftListActionTypes.GetAircraftListSuccess:
        // @see https://immerjs.github.io/immer/typescript/#cast-utilities
        state.data ??= [];
        state.data.push(...castDraft(action.payload.data));

        state.isLoading = false;
        state.error = null;
        state.total = action.payload.total;
        state.page = action.payload.page;

        break;

      case AircraftListActionTypes.GetAircraftListFailure:
        state.isLoading = false;
        state.error = action.payload;

        break;

      case AircraftListActionTypes.GetAircraftListDataNextPage:
        state.isLoading = true;

        break;

      case AircraftListActionTypes.SetAircraftListParameters:
        if (action.payload.orderBy) {
          state.orderBy = action.payload.orderBy;
        }

        if (action.payload.orderDirection) {
          state.orderDirection = action.payload.orderDirection;
        }

        if (action.payload.filters) {
          state.filters = { ...state.filters, ...action.payload.filters };
        }

        break;

      case AircraftListActionTypes.DeleteAircraftSuccess:
        state.data =
          state.data?.filter(
            (item: AircraftDetailDto) => item.id !== action.payload,
          ) ?? [];

        state.total = state.data.length;

        break;

      default:
        return state;
    }
  },
  initialAircraftListState,
);

export default aircraftListReducer;
