import dayjs from 'dayjs'

import { DisplayWeekStartDays } from '@strafos/common'

export const setWeekStart = (nextValue: DisplayWeekStartDays) => {
  const weekStartEnumToDayjsMap: Record<DisplayWeekStartDays, number> = {
    [DisplayWeekStartDays.Monday]: 1,
    [DisplayWeekStartDays.Sunday]: 7,
  }

  dayjs.Ls.en.weekStart = weekStartEnumToDayjsMap[nextValue]
}
