import { ActionType } from "typesafe-actions";
import produce, { castDraft } from "immer";

import { UserOperatorsActionTypes } from "@app/store/core/userOperators/userOperators.constants";
import { BaseDataStore } from "@app/store/types";
import * as userOperatorsActions from "@app/store/core/userOperators/userOperators.actions";
import { OperatorDto } from "@strafos/common";

type UserOperatorsAction = ActionType<typeof userOperatorsActions>;

export interface UserOperatorsStore extends BaseDataStore<OperatorDto[]> {
  selectedOperator: OperatorDto | null;
}

const initialUserOperatorsState: UserOperatorsStore = {
  error: null,
  isLoading: false,
  data: null,
  selectedOperator: null,
};

const userOperatorsReducer = produce<UserOperatorsStore, [UserOperatorsAction]>(
  (state, action) => {
    switch (action.type) {
      case UserOperatorsActionTypes.GetUserOperators:
        state.isLoading = true;
        state.data = null;
        state.error = null;

        break;

      case UserOperatorsActionTypes.GetUserOperatorsSuccess:
        state.isLoading = false;
        state.data = action.payload.response.map(castDraft);
        state.error = null;

        const selectedOperatorId =
          action.payload.operatorToSelect ?? state.selectedOperator?.id;

        state.selectedOperator =
          state.data.find((operator) => operator.id === selectedOperatorId) ??
          castDraft(action.payload.response[0]);

        break;

      case UserOperatorsActionTypes.GetUserOperatorsFailure:
        state.isLoading = false;
        state.error = action.payload;
        state.data = null;

        break;

      case UserOperatorsActionTypes.SetSelectedOperator:
        state.selectedOperator = action.payload;

        break;

      case UserOperatorsActionTypes.ResetUserOperatorsState:
        return initialUserOperatorsState;

      default:
        return state;
    }
  },
  initialUserOperatorsState,
);

export default userOperatorsReducer;
