import { ActionType } from "typesafe-actions";
import produce, { castDraft } from "immer";

import { GetContactPersonsFilters } from "@app/utils/api/types";
import { ContactPersonDetailDto } from "@strafos/common";
import { ContactPersonsListActionTypes } from "@app/store/pages/clients/contactPersonsList/contactPersonsList.constants";
import { ListStore } from "@app/store/types";
import { ContactPersonsOrderBy } from "@strafos/common";
import * as contactPersonsListActions from "@app/store/pages/clients/contactPersonsList/contactPersonsList.actions";

import {
  DEFAULT_ORDER_BY,
  DEFAULT_ORDER_DIRECTION,
  DEFAULT_PAGE_LIMIT,
} from "@app/constants";

type ContactPersonsListAction = ActionType<typeof contactPersonsListActions>;

export type ContactPersonsListStore = Omit<
  ListStore<ContactPersonDetailDto, GetContactPersonsFilters>,
  "orderBy"
> & {
  isReloading: boolean;
  orderBy: ContactPersonsOrderBy;
};

const initialContactPersonsListState: ContactPersonsListStore = {
  error: null,
  isLoading: false,
  isReloading: false,
  data: null,
  total: null,
  page: 1,
  limit: DEFAULT_PAGE_LIMIT,
  orderBy: DEFAULT_ORDER_BY as ContactPersonsOrderBy,
  orderDirection: DEFAULT_ORDER_DIRECTION,
  filters: {},
};

const ContactPersonsListReducer = produce<
  ContactPersonsListStore,
  [ContactPersonsListAction]
>((state, action) => {
  switch (action.type) {
    case ContactPersonsListActionTypes.GetContactPersonsListDataFirstPage:
      state.isLoading = true;
      state.data = null;
      state.error = null;
      state.total = 0;
      state.limit = DEFAULT_PAGE_LIMIT;
      state.page = 1;

      break;

    case ContactPersonsListActionTypes.GetContactPersonsListSuccess:
      // @see https://immerjs.github.io/immer/typescript/#cast-utilities
      state.data ??= [];
      state.data.push(...castDraft(action.payload.data));

      state.isLoading = false;
      state.error = null;
      state.total = action.payload.total;
      state.page = action.payload.page;

      break;

    case ContactPersonsListActionTypes.GetContactPersonsListFailure:
      state.isLoading = false;
      state.error = action.payload;

      break;

    case ContactPersonsListActionTypes.GetContactPersonsListDataNextPage:
      state.isLoading = true;

      break;

    case ContactPersonsListActionTypes.ResetContactPersonsListFilters:
      state.filters = {};

      break;

    case ContactPersonsListActionTypes.SetContactPersonsListSort:
      if (action.payload.orderBy) {
        state.orderBy = action.payload.orderBy;
      }

      if (action.payload.orderDirection) {
        state.orderDirection = action.payload.orderDirection;
      }

      break;

    case ContactPersonsListActionTypes.SetContactPersonsListFilters:
      if (action.payload.filters) {
        state.filters = { ...state.filters, ...action.payload.filters };
      }

      break;

    case ContactPersonsListActionTypes.ReloadContactPersonsList:
      state.isReloading = true;

      break;

    case ContactPersonsListActionTypes.ReloadContactPersonsListSuccess:
      state.isReloading = false;
      state.error = null;
      state.data = action.payload.data;
      state.total = action.payload.total;

      break;

    case ContactPersonsListActionTypes.ResetContactPersonsList:
      state.error = initialContactPersonsListState.error;
      state.isLoading = initialContactPersonsListState.isLoading;
      state.data = initialContactPersonsListState.data;
      state.total = initialContactPersonsListState.total;
      state.page = initialContactPersonsListState.page;
      state.limit = initialContactPersonsListState.limit;
      state.orderBy = initialContactPersonsListState.orderBy;
      state.orderDirection = initialContactPersonsListState.orderDirection;
      state.filters = initialContactPersonsListState.filters;

      break;

    default:
      return state;
  }
}, initialContactPersonsListState);

export default ContactPersonsListReducer;
