import { action } from "typesafe-actions";

import { ClientsActionTypes } from "@app/store/pages/clients/clientsList/clientsList.constants";
import { ClientsSortProperties } from "@app/store/pages/clients/clientsList/clientsList.types";

import { GetClientsFilters } from "@app/utils/api/types";

import { ClientDetailDto, PaginatedList } from "@strafos/common";

export const getClientsDataFirstPageAction = () =>
  action(ClientsActionTypes.GetClientsDataFirstPage);

export const getClientsDataNextPageAction = () =>
  action(ClientsActionTypes.GetClientsDataNextPage);

export const getClientsSuccessAction = (
  response: PaginatedList<ClientDetailDto>,
) => action(ClientsActionTypes.GetClientsSuccess, response);

export const getClientsFailureAction = (error: unknown) =>
  action(ClientsActionTypes.GetClientsFailure, error);

export const setClientsSortAction = (sort: ClientsSortProperties) =>
  action(ClientsActionTypes.SetClientsSort, sort);

export const setClientsFiltersAction = (
  filters: Partial<GetClientsFilters>,
  requestDebounceInMilliseconds = 0,
) =>
  action(ClientsActionTypes.SetClientsFilters, {
    filters,
    requestDebounceInMilliseconds,
  });

export const resetClientsFiltersAction = () =>
  action(ClientsActionTypes.ResetClientsFilters);

export const resetClientsAction = () => action(ClientsActionTypes.ResetClients);

export const reloadClientsAction = () =>
  action(ClientsActionTypes.ReloadClients);

export const reloadClientsSuccessAction = (
  response: PaginatedList<ClientDetailDto>,
) => action(ClientsActionTypes.ReloadClientsSuccess, response);

export const deleteClientAction = (id: number) =>
  action(ClientsActionTypes.DeleteClient, { id });

export const deleteClientSuccessAction = () =>
  action(ClientsActionTypes.DeleteClientSuccess);

export const deleteClientFailureAction = (error: unknown) =>
  action(ClientsActionTypes.DeleteClientFailure, { error });

export const cancelClientDeleteAction = () =>
  action(ClientsActionTypes.CancelClientDelete);
