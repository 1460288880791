import { action } from 'typesafe-actions'

import { UserStatuses } from '@strafos/common'
import { MyTeamManagementActionTypes } from '@app/store/pages/myTeam/myTeamManagement/myTeamManagement.constants'

export const putUserStatusAction = (userId: number, status: UserStatuses) =>
  action(MyTeamManagementActionTypes.PutUserStatus, { userId, status })

export const putUserStatusSuccessAction = () =>
  action(MyTeamManagementActionTypes.PutUserStatusSuccess)

export const putUserStatusFailureAction = (error: unknown) =>
  action(MyTeamManagementActionTypes.PutUserStatusFailure, { error })
