import { ActionType } from "typesafe-actions";
import produce, { castDraft } from "immer";

import { UserInfoActionTypes } from "@app/store/core/userInfo/userInfo.constants";
import { BaseDataStore } from "@app/store/types";
import * as userInfoActions from "@app/store/core/userInfo/userInfo.actions";
import { UserDto } from "@strafos/common";

type UserInfoAction = ActionType<typeof userInfoActions>;

export interface UserInfoStore extends BaseDataStore<UserDto> {
  isPatchUserLoading: boolean;
  isPutPasswordLoading: boolean;
}

const initialUserInfoState: UserInfoStore = {
  error: null,
  isLoading: false,
  isPatchUserLoading: false,
  isPutPasswordLoading: false,
  data: null,
};

const userInfoReducer = produce<UserInfoStore, [UserInfoAction]>(
  (state, action) => {
    switch (action.type) {
      case UserInfoActionTypes.GetMyUserInfo:
        state.isLoading = true;
        state.data = null;
        state.error = null;

        break;

      case UserInfoActionTypes.GetMyUserInfoSuccess:
        state.isLoading = false;
        state.data = castDraft(action.payload);
        state.error = null;

        break;

      case UserInfoActionTypes.GetMyUserInfoFailure:
        state.isLoading = false;
        state.error = action.payload;
        state.data = null;

        break;

      case UserInfoActionTypes.GetUserInfo:
        state.isLoading = true;
        state.data = null;
        state.error = null;

        break;

      case UserInfoActionTypes.GetUserInfoSuccess:
        state.isLoading = false;
        state.data = castDraft(action.payload);
        state.error = null;

        break;

      case UserInfoActionTypes.GetUserInfoFailure:
        state.isLoading = false;
        state.error = action.payload;
        state.data = null;

        break;

      case UserInfoActionTypes.PatchMyUser:
        state.isPatchUserLoading = true;

        break;

      case UserInfoActionTypes.PatchMyUserSuccess:
        state.data = action.payload;
        state.isPatchUserLoading = false;

        break;

      case UserInfoActionTypes.PatchMyUserFailure:
        state.error = action.payload;
        state.isPatchUserLoading = false;

        break;

      case UserInfoActionTypes.PatchUser:
        state.isPatchUserLoading = true;

        break;

      case UserInfoActionTypes.PatchUserSuccess:
        state.isPatchUserLoading = false;

        break;

      case UserInfoActionTypes.PatchUserFailure:
        state.error = action.payload;
        state.isPatchUserLoading = false;

        break;

      case UserInfoActionTypes.PutMyPassword:
        state.isPutPasswordLoading = true;

        break;

      case UserInfoActionTypes.PutMyPasswordSuccess:
        state.isPutPasswordLoading = false;

        break;

      case UserInfoActionTypes.PutMyPasswordFailure:
        state.isPutPasswordLoading = false;
        state.error = action.payload;

        break;

      case UserInfoActionTypes.ResetUserInfoState:
        return initialUserInfoState;

      default:
        return state;
    }
  },
  initialUserInfoState,
);

export default userInfoReducer;
