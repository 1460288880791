// withTranslation

import { call, put, takeLatest } from 'redux-saga/effects'
import { navigate } from '@reach/router'

import { api } from '@app/utils/api/api'
import { getErrorMessage } from '@app/utils/errorHandling'
import { AuthActionTypes } from '@app/store/core/auth/auth.constants'
import { addNotificationAction } from '@app/store/ui/notifications/notifications.actions'
import { LocalStorageKeys } from '@app/constants'
import { Routes } from '@strafos/common'

import * as actions from '@app/store/core/auth/auth.actions'
import * as userInfoActions from '@app/store/core/userInfo/userInfo.actions'
import * as userOperatorsActions from '@app/store/core/userOperators/userOperators.actions'

function* logoutSaga() {
  try {
    yield call(api.logout)

    localStorage.removeItem(LocalStorageKeys.AuthToken)

    yield put(userInfoActions.resetUserInfoAction())
    yield put(userOperatorsActions.resetUserOperatorsStateAction())

    yield put(
      addNotificationAction({
        // t('messages.logout.success')
        i18nextKey: 'messages.logout.success',
        type: 'success',
      }),
    )

    navigate(Routes.Login)

    yield put(actions.logoutSuccessAction())
  } catch (error) {
    const errorMessageKey = getErrorMessage(error, {
      // t('errors.general.unauthorized')
      401: 'errors.general.unauthorized',
      // t('errors.logout.notFound')
      404: 'errors.logout.notFound',
      // t('errors.logout.default')
      default: 'errors.logout.default',
    })

    if (errorMessageKey) {
      yield put(
        addNotificationAction({
          i18nextKey: errorMessageKey,
          type: 'error',
        }),
      )
    }

    yield put(actions.logoutFailureAction(error))
  }
}

function* requestPasswordChangeSaga(
  action: ReturnType<typeof actions.requestPasswordChangeAction>,
) {
  try {
    yield call(api.requestPasswordChange, {
      ...action.payload.data,
      reset_url: `${window.location.origin}${Routes.PasswordReset}`,
    })

    yield put(
      addNotificationAction({
        // t('messages.requestPasswordChange.success')
        i18nextKey: 'messages.requestPasswordChange.success',
        type: 'success',
      }),
    )

    yield put(actions.requestPasswordChangeSuccessAction())
  } catch (error) {
    console.error(error)

    const errorMessageKey = getErrorMessage(error, {
      // t('errors.general.unauthorized')
      401: 'errors.general.unauthorized',
      // t('errors.requestPasswordChange.notFound')
      404: 'errors.requestPasswordChange.notFound',
      // t('errors.requestPasswordChange.default')
      default: 'errors.requestPasswordChange.default',
    })

    if (errorMessageKey) {
      yield put(
        addNotificationAction({
          i18nextKey: errorMessageKey,
          type: 'error',
        }),
      )
    }

    yield put(actions.requestPasswordChangeFailureAction(error))
  }
}

function* logoutFromAllDevicesSaga(
  action: ReturnType<typeof actions.logoutFromAllDevicesAction>,
) {
  try {
    yield call(api.logoutFromAllSessions, action.payload.token)

    yield put(
      addNotificationAction({
        // t('messages.logoutFromAllDevices.success')
        i18nextKey: 'messages.logoutFromAllDevices.success',
        type: 'success',
      }),
    )

    yield put(actions.logoutFromAllDevicesSuccessAction())
  } catch (error) {
    const errorMessageKey = getErrorMessage(error, {
      // t('errors.general.unauthorized')
      401: 'errors.general.unauthorized',
      // t('errors.logoutFromAllDevices.notFound')
      404: 'errors.logoutFromAllDevices.notFound',
      // t('errors.logoutFromAllDevices.default')
      default: 'errors.logoutFromAllDevices.default',
    })

    if (errorMessageKey) {
      yield put(
        addNotificationAction({
          i18nextKey: errorMessageKey,
          type: 'error',
        }),
      )
    }

    yield put(actions.logoutFromAllDevicesFailureAction(error))
  }
}

function* changePasswordSaga(
  action: ReturnType<typeof actions.changePasswordAction>,
) {
  try {
    yield call(api.resetPassword, action.payload.data)

    yield put(
      addNotificationAction({
        // t('messages.changePassword.success')
        i18nextKey: 'messages.changePassword.success',
        type: 'success',
      }),
    )

    yield put(actions.changePasswordSuccessAction())
  } catch (error) {
    const errorMessageKey = getErrorMessage(error, {
      // t('errors.changePassword.unauthorized')
      401: 'errors.changePassword.unauthorized',
      // t('errors.changePassword.notFound')
      404: 'errors.changePassword.notFound',
      // t('errors.changePassword.default')
      default: 'errors.changePassword.default',
    })

    if (errorMessageKey) {
      yield put(
        addNotificationAction({
          i18nextKey: errorMessageKey,
          type: 'error',
        }),
      )
    }

    yield put(actions.changePasswordFailureAction(error))
  }
}

export default function* watchAuthSaga(): Generator {
  yield takeLatest(AuthActionTypes.Logout, logoutSaga)

  yield takeLatest(
    AuthActionTypes.RequestPasswordChange,
    requestPasswordChangeSaga,
  )

  yield takeLatest(
    AuthActionTypes.LogoutFromAllDevices,
    logoutFromAllDevicesSaga,
  )

  yield takeLatest(AuthActionTypes.ChangePassword, changePasswordSaga)
}
