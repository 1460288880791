// withTranslation

import axios, { AxiosResponse } from "axios";
import produce from "immer";

import {
  call,
  delay,
  put,
  race,
  select,
  take,
  takeLatest,
} from "redux-saga/effects";

import { api } from "@app/utils/api/api";
import { AirportFeesActionTypes } from "@app/store/api/airportFees/airportFees.constants";
import { addNotificationAction } from "@app/store/ui/notifications/notifications.actions";
import { selectSelectedOperator } from "@app/store/core/userOperators/userOperators.selectors";
import * as actions from "@app/store/api/airportFees/airportFees.actions";
import * as dialogActions from "@app/store/ui/confirmationDialog/confirmationDialog.actions";
import { ConfirmationDialogActionTypes } from "@app/store/ui/confirmationDialog/confirmationDialog.constants";
import { getErrorMessage } from "@app/utils/errorHandling";

import { PostAirportFeeErrorResponse } from "@app/utils/api/types";

import {
  selectAirportFeesFilters,
  selectAirportFeesLimit,
  selectAirportFeesOrderBy,
  selectAirportFeesOrderDirection,
  selectAirportFeesPage,
} from "@app/store/api/airportFees/airportFees.selectors";
import {
  AirportFeeDto,
  GetAirportFeesQuery,
  PaginatedList,
} from "@strafos/common";

function* getAirportFeesDataSaga(page: number) {
  try {
    const limit = selectAirportFeesLimit(yield select());
    const orderDirection = selectAirportFeesOrderDirection(yield select());
    const orderBy = selectAirportFeesOrderBy(yield select());
    const operator = selectSelectedOperator(yield select());
    const { aircraft, ...filters } = selectAirportFeesFilters(yield select());

    if (!operator) {
      throw new Error("Operator must be selected");
    }

    const params: GetAirportFeesQuery = {
      ...filters,
      orderBy,
      orderDirection: orderDirection ?? "ASC",
      limit,
      page,
      aircraft_registration_code: aircraft?.registration_code,
    };

    const { data }: AxiosResponse<PaginatedList<AirportFeeDto>> = yield call(
      api.listAirportFees,
      params,
    );

    yield put(actions.getAirportFeesSuccessAction(data));
  } catch (error) {
    console.error(error);

    const errorMessageKey = getErrorMessage(error, {
      // t('errors.general.unauthorized')
      401: "errors.general.unauthorized",
      // t('errors.getAirportFees.default')
      default: "errors.getAirportFees.default",
    });

    if (errorMessageKey) {
      yield put(
        addNotificationAction({
          i18nextKey: errorMessageKey,
          type: "error",
        }),
      );
    }

    yield put(actions.getAirportFeesFailureAction(error));
  }
}

function* getInitialAirportFeesDataSaga() {
  yield getAirportFeesDataSaga(1);
}

function* getMoreAirportFeesDataSaga() {
  const page = selectAirportFeesPage(yield select());

  yield getAirportFeesDataSaga(page + 1);
}

function* setAirportFeesFiltersSaga(
  action: ReturnType<typeof actions.setAirportFeesFiltersAction>,
) {
  try {
    const { requestDebounceInMilliseconds } = action.payload;

    const { filtersChanged } = yield race({
      filtersChanged: take(AirportFeesActionTypes.SetAirportFeesFilters),
      timeout: delay(requestDebounceInMilliseconds),
    });

    if (filtersChanged) {
      return;
    }

    yield put(actions.getAirportFeesDataFirstPageAction());
  } catch (error) {
    console.error(error);

    yield put(actions.getAirportFeesFailureAction(error));
  }
}

function* setAirportFeesSortSaga() {
  try {
    yield put(actions.getAirportFeesDataFirstPageAction());
  } catch (error) {
    console.error(error);

    yield put(actions.getAirportFeesFailureAction(error));
  }
}

function* postAirportFeeSaga(
  action: ReturnType<typeof actions.postAirportFeeAction>,
) {
  try {
    const operator = selectSelectedOperator(yield select());

    if (!operator) {
      throw new Error("Operator must be selected");
    }

    const { data }: AxiosResponse<{ id: number }> = yield call(
      api.createAirportFee,
      {
        matcher: action.payload.matcher,
        aircraft_id: action.payload.aircraft_id,
        airport_fee: action.payload.airport_fee,
        handling_fee: action.payload.handling_fee,
        departure_fee: action.payload.departure_fee,
        arrival_fee: action.payload.arrival_fee,
      },
    );

    yield put(
      addNotificationAction({
        // t('messages.createAirportFee.success')
        i18nextKey: "messages.createAirportFee.success",
        type: "success",
      }),
    );

    yield put(actions.reloadAirportFeeListAction());
    yield put(actions.postAirportFeeSuccessAction(data));
  } catch (error) {
    console.error(error);

    const getIsPostAirportFeeErrorResponse = (
      error: unknown,
    ): error is PostAirportFeeErrorResponse =>
      !!(error as PostAirportFeeErrorResponse).conflicting_resource_id;

    if (
      axios.isAxiosError(error) &&
      error.response?.status === 409 &&
      getIsPostAirportFeeErrorResponse(error.response?.data)
    ) {
      yield put(
        dialogActions.openConfirmationDialogAction({
          // t('confirmation.postAirportFeeConflict')
          i18nextKey: "confirmation.postAirportFeeConflict",
        }),
      );

      const { cancel } = yield race({
        submit: take(ConfirmationDialogActionTypes.SubmitConfirmationDialog),
        cancel: take(ConfirmationDialogActionTypes.CloseConfirmationDialog),
      });

      if (cancel) {
        yield put(actions.cancelPostAirportFeeLoadingAction());
        return;
      }

      try {
        yield put(
          actions.patchAirportFeeAction(
            error.response.data.conflicting_resource_id,
            action.payload,
          ),
        );

        yield put(actions.cancelPostAirportFeeLoadingAction());
        yield put(dialogActions.closeConfirmationDialogAction());

        return;
      } catch (error) {
        yield put(
          addNotificationAction({
            // t('errors.createAirportFee.default')
            i18nextKey: "errors.createAirportFee.default",
            type: "error",
          }),
        );

        yield put(actions.postAirportFeeFailureAction(error));
      }

      return;
    }

    const errorMessageKey = getErrorMessage(error, {
      // t('errors.general.unauthorized')
      401: "errors.general.unauthorized",
      // t('errors.createAirportFee.notFound')
      404: "errors.createAirportFee.notFound",
      // t('errors.createAirportFee.conflict')
      409: "errors.createAirportFee.conflict",
      // t('errors.createAirportFee.default')
      default: "errors.createAirportFee.default",
    });

    if (errorMessageKey) {
      yield put(
        addNotificationAction({
          i18nextKey: errorMessageKey,
          type: "error",
        }),
      );
    }

    yield put(actions.postAirportFeeFailureAction(error));
  }
}

function* patchAirportFeeSaga(
  action: ReturnType<typeof actions.patchAirportFeeAction>,
) {
  try {
    const { data }: AxiosResponse<AirportFeeDto> = yield call(
      api.updateAirportFee,
      action.payload.id,
      action.payload.data,
    );

    yield put(
      addNotificationAction({
        // t('messages.updateAirportFee.success')
        i18nextKey: "messages.updateAirportFee.success",
        type: "success",
      }),
    );

    yield put(actions.reloadAirportFeeListAction());
    yield put(actions.patchAirportFeeSuccessAction(data));
  } catch (error) {
    console.error(error);

    const errorMessageKey = getErrorMessage(error, {
      // t('errors.general.unauthorized')
      401: "errors.general.unauthorized",
      // t('errors.updateAirportFee.notFound')
      404: "errors.updateAirportFee.notFound",
      // t('errors.updateAirportFee.conflict')
      409: "errors.updateAirportFee.conflict",
      // t('errors.updateAirportFee.default')
      default: "errors.updateAirportFee.default",
    });

    if (errorMessageKey) {
      yield put(
        addNotificationAction({
          i18nextKey: errorMessageKey,
          type: "error",
        }),
      );
    }

    yield put(actions.patchAirportFeeFailureAction(error));
  }
}

function* deleteAirportFeeSaga(
  action: ReturnType<typeof actions.deleteAirportFeeAction>,
) {
  try {
    yield put(
      dialogActions.openConfirmationDialogAction({
        // t('confirmation.deleteAirportFeeConfirmation')
        i18nextKey: "confirmation.deleteAirportFeeConfirmation",
      }),
    );

    const { cancel } = yield race({
      submit: take(ConfirmationDialogActionTypes.SubmitConfirmationDialog),
      cancel: take(ConfirmationDialogActionTypes.CloseConfirmationDialog),
    });

    if (cancel) {
      yield put(actions.cancelDeleteAirportFeeLoadingAction());

      return;
    }

    yield call(api.deleteAirportFee, action.payload);

    yield put(dialogActions.closeConfirmationDialogAction());

    yield put(
      addNotificationAction({
        // t('messages.deleteAirportFee.success')
        i18nextKey: "messages.deleteAirportFee.success",
        type: "success",
      }),
    );

    yield put(actions.reloadAirportFeeListAction());
    yield put(actions.deleteAirportFeeSuccessAction());
  } catch (error) {
    console.error(error);

    const errorMessageKey = getErrorMessage(error, {
      // t('errors.general.unauthorized')
      401: "errors.general.unauthorized",
      // t('errors.deleteAirportFee.notFound')
      404: "errors.deleteAirportFee.notFound",
      // t('errors.deleteAirportFee.default')
      default: "errors.deleteAirportFee.default",
    });

    if (errorMessageKey) {
      yield put(
        addNotificationAction({
          i18nextKey: errorMessageKey,
          type: "error",
        }),
      );
    }

    yield put(actions.deleteAirportFeeFailureAction(error));
  }
}

function* reloadAirportFeesListSaga() {
  const page = selectAirportFeesPage(yield select());
  const limit = selectAirportFeesLimit(yield select());
  const orderDirection = selectAirportFeesOrderDirection(yield select());
  const filters = selectAirportFeesFilters(yield select());
  const orderBy = selectAirportFeesOrderBy(yield select());
  const operator = selectSelectedOperator(yield select());

  if (!operator) {
    throw new Error("Operator must be selected");
  }

  const params: GetAirportFeesQuery = {
    ...filters,
    orderBy,
    orderDirection: orderDirection ?? "ASC",
    limit,
    page: 1,
  };

  try {
    const { data }: AxiosResponse<PaginatedList<AirportFeeDto>> = yield call(
      api.listAirportFees,
      produce(params, (draft) => {
        if (draft.limit && page) {
          draft.limit = draft.limit * page;
        }
      }),
    );

    yield put(actions.setAirportFeesDataAction(data));
  } catch (error) {
    console.error(error);

    yield put(actions.getAirportFeesFailureAction(error));
  }
}

export default function* watchAirportFeesSaga(): Generator {
  yield takeLatest(
    AirportFeesActionTypes.GetAirportFeesDataFirstPage,
    getInitialAirportFeesDataSaga,
  );

  yield takeLatest(
    AirportFeesActionTypes.GetAirportFeesDataNextPage,
    getMoreAirportFeesDataSaga,
  );

  yield takeLatest(
    AirportFeesActionTypes.SetAirportFeesSort,
    setAirportFeesSortSaga,
  );

  yield takeLatest(
    AirportFeesActionTypes.SetAirportFeesFilters,
    setAirportFeesFiltersSaga,
  );

  yield takeLatest(AirportFeesActionTypes.PostAirportFee, postAirportFeeSaga);

  yield takeLatest(AirportFeesActionTypes.PatchAirportFee, patchAirportFeeSaga);

  yield takeLatest(
    AirportFeesActionTypes.DeleteAirportFee,
    deleteAirportFeeSaga,
  );

  yield takeLatest(
    AirportFeesActionTypes.ReloadAirportFees,
    reloadAirportFeesListSaga,
  );
}
