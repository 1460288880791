import { action } from "typesafe-actions";

import { UserRegistrationActionTypes } from "@app/store/pages/userRegistration/userRegistration/userRegistration.constants";
import { UserRegistrationSteps, UserRegistrationTypes } from "@strafos/common";

import {
  InitialRegistrationResponseInvitationDataDto,
  InitialRegistrationResponseUserDataDto,
  UserRegistrationDto,
  OperatorRegistrationDto,
  CompanyRegistrationDto,
} from "@strafos/common";

export const initRegistrationAction = (userHash: string) =>
  action(UserRegistrationActionTypes.InitRegistration, userHash);

export const setUserRegistrationTokenAction = (userRegistrationToken: string) =>
  action(
    UserRegistrationActionTypes.SetUserRegistrationToken,
    userRegistrationToken,
  );

export const setUserRegistrationTypeAction = (
  userRegistrationType: UserRegistrationTypes,
) =>
  action(
    UserRegistrationActionTypes.SetUserRegistrationType,
    userRegistrationType,
  );

export const setUserRegistrationInvitationDataAction = (
  invitationData: InitialRegistrationResponseInvitationDataDto[],
) =>
  action(
    UserRegistrationActionTypes.SetUserRegistrationInvitationData,
    invitationData,
  );

export const setUserRegistrationDataAction = (
  userData: InitialRegistrationResponseUserDataDto,
) => action(UserRegistrationActionTypes.SetUserRegistrationData, userData);

export const updateUserDataAction = (userData: UserRegistrationDto) =>
  action(UserRegistrationActionTypes.UpdateUserData, userData);

export const addOperatorAction = () =>
  action(UserRegistrationActionTypes.AddOperator);

export const updateOperatorDataAction = (
  index: number,
  operatorData: OperatorRegistrationDto,
) =>
  action(UserRegistrationActionTypes.UpdateOperatorData, {
    data: operatorData,
    index,
  });

export const updateCompanyDataAction = (companyData: CompanyRegistrationDto) =>
  action(UserRegistrationActionTypes.UpdateCompanyData, companyData);

export const setUserRegistrationActiveStepAction = (
  nextStep: UserRegistrationSteps,
) =>
  action(UserRegistrationActionTypes.SetUserRegistrationActiveStep, nextStep);

export const submitRegistrationDataAction = () =>
  action(UserRegistrationActionTypes.SubmitRegistrationData);

export const resetRegistrationAction = () =>
  action(UserRegistrationActionTypes.ResetRegistration);

export const submitRegistrationDataSuccessAction = () =>
  action(UserRegistrationActionTypes.SubmitRegistrationDataSuccess);

export const submitRegistrationDataFailureAction = () =>
  action(UserRegistrationActionTypes.SubmitRegistrationDataFailure);
