import { ActionType } from "typesafe-actions";
import produce from "immer";

import { BaseDataStore } from "@app/store/types";
import * as createRequestActions from "@app/store/pages/requests/createRequest/createRequest.actions";
import { CreateRequestActionTypes } from "@app/store/pages/requests/createRequest/createRequest.constants";
import { OffersComputationResultDto } from "@strafos/common";

type CreateRequestAction = ActionType<typeof createRequestActions>;

export type CreateRequestStore = BaseDataStore<OffersComputationResultDto> & {
  computationId: number | null;
  isPolling: boolean;
};

const initialCreateRequestState: CreateRequestStore = {
  error: null,
  isLoading: false,
  isPolling: false,
  data: null,
  computationId: null,
};

const createRequestReducer = produce<CreateRequestStore, [CreateRequestAction]>(
  (state, action) => {
    switch (action.type) {
      case CreateRequestActionTypes.TriggerComputationPolling:
        state.isPolling = true;

        break;

      case CreateRequestActionTypes.PostComputationFailure:
        state.isPolling = false;
        state.error = action.payload;

        break;

      case CreateRequestActionTypes.WatchComputationSuccess:
        state.isPolling = false;
        state.data = action.payload.data;
        state.computationId = action.payload.computationId;
        state.error = null;

        break;

      case CreateRequestActionTypes.WatchComputationFailure:
        state.isPolling = false;
        state.error = action.payload;

        break;

      case CreateRequestActionTypes.PostCreateOffersFromComputation:
        state.isLoading = true;

        break;

      case CreateRequestActionTypes.PostCreateOffersFromComputationSuccess:
        state.isLoading = false;
        state.error = null;

        break;

      case CreateRequestActionTypes.PostCreateOffersFromComputationFailure:
        state.isLoading = true;
        state.error = action.payload;

        break;

      case CreateRequestActionTypes.StopComputationPolling:
        state.isLoading = false;

        break;

      case CreateRequestActionTypes.ResetCreateRequestsState:
        return initialCreateRequestState;

      default:
        return state;
    }
  },
  initialCreateRequestState,
);

export default createRequestReducer;
