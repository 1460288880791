import { action } from "typesafe-actions";

import { ClientsActionTypes } from "@app/store/api/clients/clients.constants";

import { ClientDetailDto, CreateClientDto } from "@strafos/common";

export const postClientAction = (postClientDto: CreateClientDto) =>
  action(ClientsActionTypes.PostClient, { postClientDto });

export const postClientSuccessAction = (response: ClientDetailDto) =>
  action(ClientsActionTypes.PostClientSuccess, { response });

export const postClientFailureAction = (error: unknown) =>
  action(ClientsActionTypes.PostClientFailure, { error });
