import { action } from "typesafe-actions";

import { RequestDetailActionTypes } from "@app/store/pages/requests/requestDetail/requestDetail.constants";
import {
  AirportFeeDto,
  BaseLegDetailDto,
  ChatMessageDto,
  CustomRouteDetailDto,
  DocumentFileType,
  OfferDto,
  OfferRecalculationResultDto,
  OfferStatuses,
  PaginatedScheduleListDto,
  RequestDetailDto,
  UpdateRequestDto,
} from "@strafos/common";

import {
  LegComputationRequest,
  OfferCalculationComputationInput,
} from "@strafos/common";

interface UpdateOfferStatusActionOptions {
  shouldSendEmail?: boolean;
  shouldSendToAvinode?: boolean;
  cancellationFee?: number;
}

export const cancelOfferAction = (id: number) =>
  action(RequestDetailActionTypes.CancelOffer, { id });

export const cancelOfferSuccessAction = () =>
  action(RequestDetailActionTypes.CancelOfferSuccess);

export const cancelOfferFailureAction = (error: unknown) =>
  action(RequestDetailActionTypes.CancelOfferFailure, { error });

export const getRequestDetailAction = (id: number, operatorId?: number) =>
  action(RequestDetailActionTypes.GetRequestDetail, { id, operatorId });

export const getRequestDetailSuccessAction = (request: RequestDetailDto) =>
  action(RequestDetailActionTypes.GetRequestDetailSuccess, { request });

export const getRequestDetailFailureAction = (error: unknown) =>
  action(RequestDetailActionTypes.GetRequestDetailFailure, { error });

export const checkOpenOfferDetailStateAction = (
  openRequestId: number,
  openOfferId: number,
) =>
  action(RequestDetailActionTypes.CheckOpenOfferDetailState, {
    openRequestId,
    openOfferId,
  });

export const updateOfferStatusAction = (
  offerId: number,
  nextStatus: OfferStatuses,
  userNote?: string,
  options?: UpdateOfferStatusActionOptions,
) =>
  action(RequestDetailActionTypes.UpdateOfferStatus, {
    offerId,
    nextStatus,
    userNote,
    cancellationFee: options?.cancellationFee ?? null,
    shouldSendEmail: options?.shouldSendEmail ?? false,
    shouldSendToAvinode: options?.shouldSendToAvinode ?? false,
  });

export const patchOfferAction = (
  offerId: number,
  partialOffer: Omit<Partial<OfferDto>, "legs"> & {
    legs: BaseLegDetailDto[];
  },
) =>
  action(RequestDetailActionTypes.PatchOffer, {
    offerId,
    partialOffer,
  });

export const patchOfferSuccessAction = () =>
  action(RequestDetailActionTypes.PatchOfferSuccess);

export const patchOfferActionFailureAction = (error: unknown) =>
  action(RequestDetailActionTypes.PatchOfferFailure, { error });

export const deleteOfferAction = (offerId: number) =>
  action(RequestDetailActionTypes.DeleteOffer, { offerId });

export const deleteOfferSuccessAction = (offerId: number) =>
  action(RequestDetailActionTypes.DeleteOfferSuccess, { offerId });

export const deleteOfferFailureAction = (error: unknown) =>
  action(RequestDetailActionTypes.DeleteOfferFailure, { error });

export const recalculateOfferAction = (
  aircraftId: number,
  requests: LegComputationRequest[],
  offerId: number,
  isBookedRecalculation: boolean = false,
  removedLegIds?: number[],
) =>
  action(RequestDetailActionTypes.RecalculateOffer, {
    aircraftId,
    requests,
    offerId,
    isBookedRecalculation,
    removedLegIds,
  });

export const getOfferDocumentIds = () =>
  action(RequestDetailActionTypes.GetOfferDocumentIds);

export const addOfferDocumentId = (id: number, type: DocumentFileType) =>
  action(RequestDetailActionTypes.AddOfferDocumentId, { id, type });

export const removeOfferDocumentIds = (id: number) =>
  action(RequestDetailActionTypes.RemoveOfferDocumentId, { id });

export const recalculateOfferSuccessAction = (
  data: OfferRecalculationResultDto,
) => action(RequestDetailActionTypes.RecalculateOfferSuccess, { data });

export const recalculateOfferFailureAction = (error: unknown) =>
  action(RequestDetailActionTypes.RecalculateOfferFailure, { error });

export const patchRequestAction = (
  requestId: number,
  partialRequest: UpdateRequestDto,
) =>
  action(RequestDetailActionTypes.PatchRequest, {
    requestId,
    partialRequest,
  });

export const patchRequestActionSuccessAction = () =>
  action(RequestDetailActionTypes.PatchRequestSuccess);

export const patchRequestActionFailureAction = (error: unknown) =>
  action(RequestDetailActionTypes.PatchRequestFailure, { error });

export const getOfferScheduleAction = (
  registrationCode: string,
  from: string,
  to: string,
) =>
  action(RequestDetailActionTypes.GetOfferSchedule, {
    from,
    to,
    registrationCode,
  });

export const getOfferScheduleSuccessAction = (
  response: PaginatedScheduleListDto,
) => action(RequestDetailActionTypes.GetOfferScheduleSuccess, response);

export const getOfferScheduleFailureAction = (error: unknown) =>
  action(RequestDetailActionTypes.GetOfferScheduleFailure, error);

export const getLegEditorScheduleAction = (
  registrationCode: string,
  from: string,
  to: string,
) =>
  action(RequestDetailActionTypes.GetLegEditorSchedule, {
    from,
    to,
    registrationCode,
  });

export const getLegEditorScheduleSuccessAction = (
  response: PaginatedScheduleListDto,
) => action(RequestDetailActionTypes.GetLegEditorScheduleSuccess, response);

export const getLegEditorScheduleFailureAction = (error: unknown) =>
  action(RequestDetailActionTypes.GetLegEditorScheduleFailure, error);

export const resetRecalculationStateAction = () =>
  action(RequestDetailActionTypes.ResetRecalculationState);

export const resetRequestDetailStateAction = () =>
  action(RequestDetailActionTypes.ResetRequestDetailState);

export const reloadRequestDetailAction = () =>
  action(RequestDetailActionTypes.ReloadRequestDetail);

export const setOpenOfferIdAction = (offerId: number | null) =>
  action(RequestDetailActionTypes.SetOpenOfferId, offerId);

export const getChatAction = (requestId: number) =>
  action(RequestDetailActionTypes.GetChat, { requestId });

export const getChatSuccessAction = (messages: ChatMessageDto[]) =>
  action(RequestDetailActionTypes.GetChatSuccess, { messages });

export const getChatFailureAction = (error: unknown) =>
  action(RequestDetailActionTypes.GetChatFailure, { error });

export const postChatMessageAction = ({
  content,
  offer_id,
}: {
  content: string;
  offer_id?: number;
}) => action(RequestDetailActionTypes.PostChatMessage, { content, offer_id });

export const postChatMessageSuccessAction = ({ id }: { id: number }) =>
  action(RequestDetailActionTypes.PostChatMessageSuccess, { id });

export const postChatMessageFailureAction = (error: unknown) =>
  action(RequestDetailActionTypes.PostChatMessageFailure, { error });

export const markChatMessagesAsReadAction = (
  requestId: number,
  latestMessageId: number,
) =>
  action(RequestDetailActionTypes.MarkChatMessagesAsRead, {
    requestId,
    latestMessageId,
  });

export const getOfferRelatedCustomRoutesAction = (
  aircraftId: number,
  airportIds: number[],
) =>
  action(RequestDetailActionTypes.GetOfferRelatedCustomRoutes, {
    aircraftId,
    airportIds,
  });

export const getOfferRelatedCustomRoutesSuccessAction = (
  data: CustomRouteDetailDto[],
) =>
  action(RequestDetailActionTypes.GetOfferRelatedCustomRoutesSuccess, {
    data,
  });

export const getOfferRelatedCustomRoutesFailureAction = (error: unknown) =>
  action(RequestDetailActionTypes.GetOfferRelatedCustomRoutesFailure, {
    error,
  });

export const getOfferRelatedAirportFees = (
  aircraftId: number,
  airportIcaoCodes: string[],
) =>
  action(RequestDetailActionTypes.GetOfferRelatedAirportFees, {
    aircraftId,
    airportIcaoCodes,
  });

export const getOfferRelatedAirportFeesSuccessAction = (
  data: AirportFeeDto[],
) =>
  action(RequestDetailActionTypes.GetOfferRelatedAirportFeesSuccess, {
    data,
  });

export const getOfferRelatedAirportFeesFailureAction = (error: unknown) =>
  action(RequestDetailActionTypes.GetOfferRelatedAirportFeesFailure, { error });

export const ignoreLegsFromOptimizationAction = (
  payload: OfferCalculationComputationInput,
) => action(RequestDetailActionTypes.IgnoreLegsFromOptimization, payload);

export const ignoreLegsFromOptimizationSuccessAction = (
  data: OfferRecalculationResultDto,
) =>
  action(RequestDetailActionTypes.IgnoreLegsFromOptimizationSuccess, {
    data,
  });

export const ignoreLegsFromOptimizationFailureAction = (error: unknown) =>
  action(RequestDetailActionTypes.IgnoreLegsFromOptimizationFailure, {
    error,
  });

export const resetIgnoreLegsFromOptimizationStateAction = () =>
  action(RequestDetailActionTypes.ResetIgnoreLegsFromOptimizationState);
