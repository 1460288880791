import { action } from "typesafe-actions";

import { ScheduleActionTypes } from "@app/store/pages/schedule/scheduleList/schedule.constants";
import { ScheduleSortProperties } from "@app/store/pages/schedule/scheduleList/schedule.types";
import { ScheduleStore } from "@app/store/pages/schedule/scheduleList/schedule.reducer";

import { GetScheduleFilters } from "@app/utils/api/types";
import { PaginatedScheduleListDto } from "@strafos/common";

export const getScheduleDataFirstPageAction = () =>
  action(ScheduleActionTypes.GetScheduleDataFirstPage);

export const getScheduleDataNextPageAction = () =>
  action(ScheduleActionTypes.GetScheduleDataNextPage);

export const getScheduleSuccessAction = (response: PaginatedScheduleListDto) =>
  action(ScheduleActionTypes.GetScheduleSuccess, response);

export const getScheduleFailureAction = (error: unknown) =>
  action(ScheduleActionTypes.GetScheduleFailure, error);

export const startSetScheduleSortAction = (sort: ScheduleSortProperties) =>
  action(ScheduleActionTypes.StartSetScheduleSort, sort);

export const startSetScheduleFiltersAction = (
  filters: Partial<GetScheduleFilters>,
  options?: {
    fetchAll?: boolean;
  },
) =>
  action(ScheduleActionTypes.StartSetScheduleFilters, {
    filters,
    fetchAll: options?.fetchAll,
  });

export const setScheduleParametersAction = (
  parameters: Partial<
    Pick<ScheduleStore, "orderBy" | "orderDirection" | "filters">
  >,
) => action(ScheduleActionTypes.SetScheduleParameters, parameters);

export const reloadScheduleListAction = () =>
  action(ScheduleActionTypes.ReloadScheduleList);

export const setScheduleDataAction = (response: PaginatedScheduleListDto) =>
  action(ScheduleActionTypes.SetScheduleListData, response);

export const getAllScheduleData = () =>
  action(ScheduleActionTypes.GetAllScheduleData);
