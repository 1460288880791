// withTranslation

import { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";

import { api } from "@app/utils/api/api";
import { getErrorMessage } from "@app/utils/errorHandling";
import { addNotificationAction } from "@app/store/ui/notifications/notifications.actions";
import { CompanySettingsActionTypes } from "@app/store/pages/settings/companySettings/companySettings.constants";
import { CompanyDto } from "@strafos/common";

import * as actions from "@app/store/pages/settings/companySettings/companySettings.actions";

function* patchCompanySaga(
  action: ReturnType<typeof actions.patchCompanyAction>,
) {
  try {
    const { data }: AxiosResponse<CompanyDto> = yield call(
      api.updateCompany,
      action.payload.id,
      action.payload.patchCompanyDto,
    );

    yield put(
      addNotificationAction({
        // t('message.patchCompany.success')
        i18nextKey: "message.patchCompany.success",
        type: "success",
      }),
    );

    yield put(actions.patchCompanySuccessAction(data));
    yield put(actions.getCompanyAction(data.id));
  } catch (error) {
    console.error(error);

    const errorMessageKey = getErrorMessage(error, {
      // t('errors.general.unauthorized')
      401: "errors.general.unauthorized",
      // t('errors.patchCompany.notFound')
      404: "errors.patchCompany.notFound",
      // t('errors.patchCompany.default')
      default: "errors.patchCompany.default",
    });

    if (errorMessageKey) {
      yield put(
        addNotificationAction({
          i18nextKey: errorMessageKey,
          type: "error",
        }),
      );
    }

    yield put(actions.patchCompanyFailureAction(error));
  }
}

function* getCompanySaga(action: ReturnType<typeof actions.getCompanyAction>) {
  try {
    const { data }: AxiosResponse<CompanyDto> = yield call(
      api.getCompany,
      action.payload.id,
    );

    yield put(actions.getCompanySuccessAction(data));
  } catch (error) {
    console.error(error);

    const errorMessageKey = getErrorMessage(error, {
      // t('errors.general.unauthorized')
      401: "errors.general.unauthorized",
      // t('errors.getCompany.notFound')
      404: "errors.getCompany.notFound",
      // t('errors.getCompany.default')
      default: "errors.getCompany.default",
    });

    if (errorMessageKey) {
      yield put(
        addNotificationAction({
          i18nextKey: errorMessageKey,
          type: "error",
        }),
      );
    }

    yield put(actions.getCompanyFailureAction(error));
  }
}

export default function* watchCompanySettingsSaga(): Generator {
  yield takeLatest(CompanySettingsActionTypes.PatchCompany, patchCompanySaga);

  yield takeLatest(CompanySettingsActionTypes.GetCompany, getCompanySaga);
}
