import { ActionType } from "typesafe-actions";
import produce, { castDraft } from "immer";

import * as AirportNotesActions from "@app/store/api/airportNotes/airportNotes.actions";
import { AirportNote } from "@strafos/common";
import { AirportNotesActionTypes } from "@app/store/api/airportNotes/airportNotes.constants";

type AirportFeesAction = ActionType<typeof AirportNotesActions>;

export type AirportNotesStore = {
  data: AirportNote[];
};
const initialAirportNotesState: AirportNotesStore = {
  data: [],
};

const AirportNotesReducer = produce<AirportNotesStore, [AirportFeesAction]>(
  (state, action) => {
    switch (action.type) {
      case AirportNotesActionTypes.GetAirportNotesDataSuccess:
        // @see https://immerjs.github.io/immer/typescript/#cast-utilities
        state.data = [];
        state.data.push(...castDraft(action.payload));

        break;

      default:
        return state;
    }
  },
  initialAirportNotesState,
);

export default AirportNotesReducer;
