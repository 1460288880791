import { action } from "typesafe-actions";

import { MyTeamListActionTypes } from "@app/store/pages/myTeam/myTeamList/myTeamList.constants";
import { MyTeamListStore } from "@app/store/pages/myTeam/myTeamList/myTeamList.reducer";
import { MyTeamListSortProperties } from "@app/store/pages/myTeam/myTeamList/myTeamList.types";
import { TeamUserDto } from "@app/utils/api/types";
import { UserDetailDto } from "@strafos/common";

export const getMyTeamListDataAction = () =>
  action(MyTeamListActionTypes.GetMyTeamListData);

export const getMyTeamListSuccessAction = (response: UserDetailDto[]) =>
  action(MyTeamListActionTypes.GetMyTeamListSuccess, response);

export const getMyTeamListFailureAction = (error: unknown) =>
  action(MyTeamListActionTypes.GetMyTeamListFailure, error);

export const getUserDataAction = (id: number) =>
  action(MyTeamListActionTypes.GetUserData, id);

export const getUserSuccessAction = (response: UserDetailDto) =>
  action(MyTeamListActionTypes.GetUserDataSuccess, response);

export const getUserFailureAction = (error: unknown) =>
  action(MyTeamListActionTypes.GetUserDataFailure, error);

export const patchUserDataAction = (id: number, body: TeamUserDto) =>
  action(MyTeamListActionTypes.PatchUserData, { id, body });

export const patchUserDataSuccessAction = () =>
  action(MyTeamListActionTypes.PatchUserDataSuccess);

export const patchUserDataFailureAction = (error: unknown) =>
  action(MyTeamListActionTypes.PatchUserDataFailure, error);

export const startSetMyTeamListSortAction = (sort: MyTeamListSortProperties) =>
  action(MyTeamListActionTypes.StartSetMyTeamListSort, sort);

export const setMyTeamListParametersAction = (
  parameters: Partial<Pick<MyTeamListStore, "orderBy" | "orderDirection">>,
) => action(MyTeamListActionTypes.SetMyTeamListParameters, parameters);
