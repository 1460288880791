import { action } from "typesafe-actions";

import { AirportDetailActionTypes } from "@app/store/pages/airportDetail/airportDetail/airportDetail.constants";
import { AirportDetailDto } from "@strafos/common";

export const getAirportDetailAction = (id: number) =>
  action(AirportDetailActionTypes.GetAirportDetail, id);

export const getAirportDetailSuccessAction = (response: AirportDetailDto) =>
  action(AirportDetailActionTypes.GetAirportDetailSuccess, response);

export const getAirportDetailFailureAction = (error: unknown) =>
  action(AirportDetailActionTypes.GetAirportDetailFailure, error);
