import { ActionType } from "typesafe-actions";
import produce from "immer";

import { UserRegistrationActionTypes } from "@app/store/pages/userRegistration/userRegistration/userRegistration.constants";
import * as userRegistrationActions from "@app/store/pages/userRegistration/userRegistration/userRegistration.actions";

import {
  UserRegistrationTypes,
  UserRegistrationSteps,
  CompanyRegistrationDto,
  InitialRegistrationResponseInvitationDataDto,
  OperatorRegistrationDto,
  UserRegistrationDto,
} from "@strafos/common";
import { userRegistrationStepTransitions } from "@strafos/common";

type UserRegistrationActions = ActionType<typeof userRegistrationActions>;

export type UserRegistrationStore = {
  activeStep: UserRegistrationSteps;
  userRegistrationType: UserRegistrationTypes | null;
  userRegistrationToken: string | null;
  invitationData: InitialRegistrationResponseInvitationDataDto[] | null;
  userData: UserRegistrationDto | null;
  operatorsData: Partial<OperatorRegistrationDto>[] | null;
  companyData: CompanyRegistrationDto | null;
  isSubmitRegistrationLoading: boolean;
};

const initialRegistrationState: UserRegistrationStore = {
  activeStep: UserRegistrationSteps.Initial,
  userRegistrationType: null,
  userRegistrationToken: null,
  invitationData: null,
  userData: null,
  operatorsData: null,
  companyData: null,
  isSubmitRegistrationLoading: false,
};

const aircraftDetailReducer = produce<
  UserRegistrationStore,
  [UserRegistrationActions]
>((state, action) => {
  switch (action.type) {
    case UserRegistrationActionTypes.SubmitRegistrationData:
      state.isSubmitRegistrationLoading = true;

      break;

    case UserRegistrationActionTypes.SubmitRegistrationDataSuccess:
      state.isSubmitRegistrationLoading = false;

      break;

    case UserRegistrationActionTypes.SubmitRegistrationDataFailure:
      state.isSubmitRegistrationLoading = false;

      break;

    case UserRegistrationActionTypes.SetUserRegistrationToken:
      state.userRegistrationToken = action.payload;

      break;

    case UserRegistrationActionTypes.SetUserRegistrationType:
      state.userRegistrationType = action.payload;

      break;

    case UserRegistrationActionTypes.SetUserRegistrationInvitationData:
      state.invitationData = action.payload;

      break;

    case UserRegistrationActionTypes.SetUserRegistrationData:
      state.userData = action.payload;

      break;

    case UserRegistrationActionTypes.SetUserRegistrationActiveStep:
      if (!state.userRegistrationType) {
        throw new Error(
          `userRegistrationType must have a value when calling '${UserRegistrationActionTypes.SetUserRegistrationActiveStep}' action`,
        );
      }

      const validTransitions =
        userRegistrationStepTransitions[state.userRegistrationType];

      const isValidTransition = validTransitions[state.activeStep].includes(
        action.payload,
      );

      if (!isValidTransition) {
        throw new Error(
          `Invalid transitions from '${state.activeStep}' to '${action.payload}' for registration type '${state.userRegistrationType}'`,
        );
      }

      state.activeStep = action.payload;

      break;

    case UserRegistrationActionTypes.UpdateUserData:
      state.userData = action.payload;

      break;

    case UserRegistrationActionTypes.UpdateCompanyData:
      state.companyData = action.payload;

      break;

    case UserRegistrationActionTypes.UpdateOperatorData:
      if (!state.operatorsData) {
        throw new Error(
          `Operators data are required when calling '${UserRegistrationActionTypes.UpdateOperatorData}' action`,
        );
      }

      if (!state.operatorsData[action.payload.index]) {
        throw new Error(
          `Operator at index '${action.payload.index}' doesn't exist`,
        );
      }

      state.operatorsData[action.payload.index] = action.payload.data;

      break;

    case UserRegistrationActionTypes.AddOperator:
      const initialOperatorData: Partial<OperatorRegistrationDto> = {
        name: undefined,
        address_city: undefined,
        address_country_id: undefined,
        address_region: undefined,
        address_street: undefined,
        address_zip_code: undefined,
        aoc_expiration_date: undefined,
        aoc_number: undefined,
        currency_id: undefined,
        icao: undefined,
      };

      state.operatorsData ??= [];
      state.operatorsData = state.operatorsData.concat(initialOperatorData);

      break;

    case UserRegistrationActionTypes.ResetRegistration:
      return initialRegistrationState;

    default:
      return state;
  }
}, initialRegistrationState);

export default aircraftDetailReducer;
