// withTranslation

import { AxiosResponse } from "axios";
import { call, put, select, takeLatest } from "redux-saga/effects";

import { api } from "@app/utils/api/api";
import { getErrorMessage } from "@app/utils/errorHandling";
import { AirportListActionTypes } from "@app/store/pages/airports/airportList/airportList.constants";
import { addNotificationAction } from "@app/store/ui/notifications/notifications.actions";
import { selectSelectedOperator } from "@app/store/core/userOperators/userOperators.selectors";

import * as actions from "@app/store/pages/airports/airportList/airportList.actions";

import {
  selectAirportListFilters,
  selectAirportListLimit,
  selectAirportListOrderBy,
  selectAirportListOrderDirection,
  selectAirportListPage,
} from "@app/store/pages/airports/airportList/airportList.selectors";

import {
  PaginatedList,
  AirportDetailDto,
  GetAirportsQuery,
} from "@strafos/common";

function* getAirportListDataSaga(page: number) {
  try {
    const limit = selectAirportListLimit(yield select());
    const orderDirection = selectAirportListOrderDirection(yield select());
    const filters = selectAirportListFilters(yield select());
    const orderBy = selectAirportListOrderBy(yield select());
    const operator = selectSelectedOperator(yield select());

    if (!operator) {
      throw new Error("Operator is required");
    }

    const params: GetAirportsQuery = {
      ...filters,
      orderBy: orderBy as string,
      orderDirection: orderDirection || "DESC",
      limit,
      page,
    };

    const { data }: AxiosResponse<PaginatedList<AirportDetailDto>> = yield call(
      api.listAirports,
      params,
    );

    yield put(actions.getAirportListSuccessAction(data));
  } catch (error) {
    console.error(error);

    const errorMessageKey = getErrorMessage(error, {
      // t('errors.general.unauthorized')
      401: "errors.general.unauthorized",
      // t('errors.getAirportList.default')
      default: "errors.getAirportList.default",
    });

    if (errorMessageKey) {
      yield put(
        addNotificationAction({
          i18nextKey: errorMessageKey,
          type: "error",
        }),
      );
    }

    yield put(actions.getAirportListFailureAction(error));
  }
}

function* getInitialAirportListDataSaga() {
  yield getAirportListDataSaga(1);
}

function* getMoreAirportListDataSaga() {
  const page = selectAirportListPage(yield select());

  yield getAirportListDataSaga(page + 1);
}

function* setAirportListGetParametersSaga(
  action:
    | ReturnType<typeof actions.startSetAirportListFiltersAction>
    | ReturnType<typeof actions.startSetAirportListSortAction>,
) {
  try {
    yield put(actions.setAirportListParametersAction(action.payload));
    yield put(actions.getAirportListDataFirstPageAction());
  } catch (error) {
    console.error(error);

    yield put(actions.getAirportListFailureAction(error));
  }
}

export default function* watchAirportListSaga(): Generator {
  yield takeLatest(
    AirportListActionTypes.GetAirportListDataFirstPage,
    getInitialAirportListDataSaga,
  );

  yield takeLatest(
    AirportListActionTypes.GetAirportListDataNextPage,
    getMoreAirportListDataSaga,
  );

  yield takeLatest(
    [
      AirportListActionTypes.StartSetAirportListFilters,
      AirportListActionTypes.StartSetAirportListSort,
    ],
    setAirportListGetParametersSaga,
  );
}
