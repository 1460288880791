import { action } from "typesafe-actions";

import { AirportListActionTypes } from "@app/store/pages/airports/airportList/airportList.constants";
import { AirportListSortProperties } from "@app/store/pages/airports/airportList/airportList.types";
import { AirportListStore } from "@app/store/pages/airports/airportList/airportList.reducer";

import { GetAirportListFilters } from "@app/utils/api/types";

import { PaginatedList, AirportDetailDto } from "@strafos/common";

export const getAirportListDataFirstPageAction = () =>
  action(AirportListActionTypes.GetAirportListDataFirstPage);

export const getAirportListDataNextPageAction = () =>
  action(AirportListActionTypes.GetAirportListDataNextPage);

export const getAirportListSuccessAction = (
  response: PaginatedList<AirportDetailDto>,
) => action(AirportListActionTypes.GetAirportListSuccess, response);

export const getAirportListFailureAction = (error: unknown) =>
  action(AirportListActionTypes.GetAirportListFailure, error);

export const startSetAirportListSortAction = (
  sort: AirportListSortProperties,
) => action(AirportListActionTypes.StartSetAirportListSort, sort);

export const startSetAirportListFiltersAction = (
  filters: Partial<GetAirportListFilters>,
) => action(AirportListActionTypes.StartSetAirportListFilters, { filters });

export const setAirportListParametersAction = (
  parameters: Partial<
    Pick<AirportListStore, "orderBy" | "orderDirection" | "filters">
  >,
) => action(AirportListActionTypes.SetAirportListParameters, parameters);
