import { action } from "typesafe-actions";

import { RequestsListActionTypes } from "@app/store/pages/requests/requestList/requestList.constants";
import { RequestsListSortProperties } from "@app/store/pages/requests/requestList/requestList.types";
import { RequestPageVariants } from "@app/components/pages/Requests/Requests";

import { GetRequestsListFilters } from "@app/utils/api/types";

import { PaginatedList, PartialRequestDto } from "@strafos/common";

export const getRequestsListDataFirstPageAction = () =>
  action(RequestsListActionTypes.GetRequestsListDataFirstPage);

export const getRequestsListDataNextPageAction = () =>
  action(RequestsListActionTypes.GetRequestsListDataNextPage);

export const getRequestsListSuccessAction = (
  response: PaginatedList<PartialRequestDto>,
) => action(RequestsListActionTypes.GetRequestsListSuccess, response);

export const getRequestsListFailureAction = (error: unknown) =>
  action(RequestsListActionTypes.GetRequestsListFailure, error);

export const setRequestsListSortAction = (sort: RequestsListSortProperties) =>
  action(RequestsListActionTypes.SetRequestsListSort, sort);

export const setRequestsListFiltersAction = (
  filters: Partial<GetRequestsListFilters>,
  requestDebounceInMilliseconds = 0,
) =>
  action(RequestsListActionTypes.SetRequestsListFilters, {
    filters,
    requestDebounceInMilliseconds,
  });

export const resetRequestsListFiltersAction = () =>
  action(RequestsListActionTypes.ResetRequestsListFilters);

export const resetRequestListAction = () =>
  action(RequestsListActionTypes.ResetRequestList);

export const reloadRequestsListSuccessAction = (
  response: PaginatedList<PartialRequestDto>,
) => action(RequestsListActionTypes.ReloadRequestsListSuccess, response);

export const reloadRequestsListAction = () =>
  action(RequestsListActionTypes.ReloadRequestsList);

export const startRequestsListPollingAction = () =>
  action(RequestsListActionTypes.StartRequestsListPolling);

export const stopRequestsListPollingAction = () =>
  action(RequestsListActionTypes.StopRequestsListPolling);

export const setOpenRequestIdAction = (requestId: number | null) =>
  action(RequestsListActionTypes.SetOpenRequestId, requestId);

export const declineRequestAction = (requestId: number) =>
  action(RequestsListActionTypes.DeclineRequest, requestId);

export const declineRequestSuccessAction = () =>
  action(RequestsListActionTypes.DeclineRequestSuccess);

export const declineRequestFailureAction = (error: unknown) =>
  action(RequestsListActionTypes.DeclineRequestFailure, error);

export const setRequestPageVariantAction = (variant: RequestPageVariants) =>
  action(RequestsListActionTypes.SetRequestPageVariant, variant);
