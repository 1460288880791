import { action } from "typesafe-actions";

import { UserOperatorsActionTypes } from "@app/store/core/userOperators/userOperators.constants";
import { OperatorDto } from "@strafos/common";

export const getUserOperatorsAction = (operatorId: number) =>
  action(UserOperatorsActionTypes.GetUserOperators, operatorId);

export const getUserOperatorsSuccessAction = (
  response: OperatorDto[],
  operatorToSelect?: number,
) =>
  action(UserOperatorsActionTypes.GetUserOperatorsSuccess, {
    response,
    operatorToSelect,
  });

export const getUserOperatorsFailureAction = (error: unknown) =>
  action(UserOperatorsActionTypes.GetUserOperatorsFailure, error);

export const setSelectedOperatorAction = (operator: OperatorDto) =>
  action(UserOperatorsActionTypes.SetSelectedOperator, operator);

export const resetUserOperatorsStateAction = () =>
  action(UserOperatorsActionTypes.ResetUserOperatorsState);
