import { ActionType } from "typesafe-actions";
import produce, { castDraft } from "immer";

import { RequestDetailActionTypes } from "@app/store/pages/requests/requestDetail/requestDetail.constants";
import * as OfferDetailActions from "@app/store/pages/requests/requestDetail/requestDetail.actions";
import {
  AirportFeeDto,
  ChatMessageDto,
  CustomRouteDetailDto,
  DocumentFileType,
  OfferRecalculationResultDto,
  RequestDetailDto,
  ScheduleDetailDto,
} from "@strafos/common";

type RequestsListAction = ActionType<typeof OfferDetailActions>;

export type RequestsListStore = {
  requestDetailData: RequestDetailDto | null;
  isRequestDetailLoading: boolean;
  requestDetailError: unknown;

  openOfferId: number | null;
  offerDocumentIds: Array<{
    id: number;
    type: DocumentFileType;
  }>;

  recalculationError: unknown;
  isRecalculationLoading: boolean;
  recalculationData: OfferRecalculationResultDto | null;

  offerSchedule: ScheduleDetailDto[] | null;
  offerScheduleError: unknown;

  legEditorSchedule: ScheduleDetailDto[] | null;
  isLegEditorScheduleLoading: boolean;
  legEditorScheduleError: unknown;

  isPatchOfferLoading: boolean;
  patchOfferError: unknown;

  isDeleteOfferLoading: boolean;
  deleteOfferError: unknown;

  isPatchRequestLoading: boolean;
  patchRequestError: unknown;

  chatMessages: ChatMessageDto[] | null;
  isChatLoading: boolean;
  chatError: unknown;

  isPostChatMessageLoading: boolean;
  postChatMessageError: unknown;

  isOfferCancellationLoading: boolean;
  offerCancellationError: unknown;

  offerRelatedCustomRoutesData: CustomRouteDetailDto[] | null;
  isOfferRelatedCustomRoutesDataLoading: boolean;
  offerRelatedCustomRoutesError: unknown;

  offerRelatedAirportFeesData: AirportFeeDto[] | null;
  isOfferRelatedAirportFeesDataLoading: boolean;
  offerRelatedAirportFeesError: unknown;

  isIgnoreLegsFromOptimizationLoading: boolean;
  ignoreLegsFromOptimizationData: OfferRecalculationResultDto | null;
  ignoreLegsFromOptimizationError: unknown;
};

const initialOfferDetailState: RequestsListStore = {
  requestDetailData: null,
  isRequestDetailLoading: false,
  requestDetailError: null,

  openOfferId: null,
  offerDocumentIds: [],

  recalculationError: null,
  isRecalculationLoading: false,
  recalculationData: null,

  offerSchedule: null,
  offerScheduleError: null,

  legEditorSchedule: null,
  isLegEditorScheduleLoading: false,
  legEditorScheduleError: null,

  isPatchOfferLoading: false,
  patchOfferError: null,

  isDeleteOfferLoading: false,
  deleteOfferError: null,

  isPatchRequestLoading: false,
  patchRequestError: null,

  chatMessages: null,
  isChatLoading: false,
  chatError: null,

  isPostChatMessageLoading: false,
  postChatMessageError: null,

  isOfferCancellationLoading: false,
  offerCancellationError: null,

  offerRelatedCustomRoutesData: null,
  isOfferRelatedCustomRoutesDataLoading: false,
  offerRelatedCustomRoutesError: null,

  offerRelatedAirportFeesData: null,
  isOfferRelatedAirportFeesDataLoading: false,
  offerRelatedAirportFeesError: null,

  isIgnoreLegsFromOptimizationLoading: false,
  ignoreLegsFromOptimizationData: null,
  ignoreLegsFromOptimizationError: null,
};

const RequestsListReducer = produce<RequestsListStore, [RequestsListAction]>(
  (state, action) => {
    switch (action.type) {
      case RequestDetailActionTypes.CancelOffer:
        state.isOfferCancellationLoading = true;
        state.offerCancellationError = null;

        break;

      case RequestDetailActionTypes.CancelOfferSuccess:
        state.isOfferCancellationLoading = false;
        state.offerCancellationError = null;

        break;

      case RequestDetailActionTypes.CancelOfferFailure:
        state.isOfferCancellationLoading = false;
        state.offerCancellationError = action.payload;

      case RequestDetailActionTypes.GetRequestDetail:
        state.isRequestDetailLoading = true;
        state.requestDetailError = null;

        break;

      case RequestDetailActionTypes.GetRequestDetailSuccess:
        state.isRequestDetailLoading = false;
        state.requestDetailData = action.payload.request;

        break;

      case RequestDetailActionTypes.AddOfferDocumentId:
        state.offerDocumentIds = [...state.offerDocumentIds, action.payload];

        break;

      case RequestDetailActionTypes.RemoveOfferDocumentId:
        state.offerDocumentIds = state.offerDocumentIds.filter(
          (d) => d.id !== action.payload.id,
        );

        break;

      case RequestDetailActionTypes.GetRequestDetailFailure:
        state.isRequestDetailLoading = false;
        state.requestDetailError = action.payload.error;

        break;

      case RequestDetailActionTypes.RecalculateOffer:
        state.isRecalculationLoading = true;
        state.recalculationError = null;

        break;

      case RequestDetailActionTypes.RecalculateOfferSuccess:
        state.isRecalculationLoading = false;
        state.recalculationData = action.payload.data;
        state.recalculationError = null;

        break;

      case RequestDetailActionTypes.RecalculateOfferFailure:
        state.recalculationError = action.payload.error;
        state.isRecalculationLoading = false;
        state.recalculationData = null;

        break;

      case RequestDetailActionTypes.PatchOffer:
        state.isPatchOfferLoading = true;
        state.patchOfferError = null;

        break;

      case RequestDetailActionTypes.PatchOfferSuccess:
        state.isPatchOfferLoading = false;

        break;

      case RequestDetailActionTypes.PatchOfferFailure:
        state.isPatchOfferLoading = false;
        state.patchOfferError = action.payload.error;

        break;

      case RequestDetailActionTypes.DeleteOffer:
        state.isDeleteOfferLoading = true;
        state.deleteOfferError = null;

        break;

      case RequestDetailActionTypes.DeleteOfferSuccess:
        state.isDeleteOfferLoading = false;

        break;

      case RequestDetailActionTypes.DeleteOfferFailure:
        state.isDeleteOfferLoading = false;
        state.deleteOfferError = action.payload.error;

        break;

      case RequestDetailActionTypes.PatchRequest:
        state.isPatchRequestLoading = true;
        state.patchRequestError = null;

        break;

      case RequestDetailActionTypes.PatchRequestSuccess:
        state.isPatchRequestLoading = false;

        break;

      case RequestDetailActionTypes.PatchRequestFailure:
        state.isPatchRequestLoading = false;
        state.patchRequestError = action.payload.error;

        break;

      case RequestDetailActionTypes.GetOfferSchedule:
        state.isRecalculationLoading = true;

        break;

      case RequestDetailActionTypes.GetOfferScheduleSuccess:
        state.offerSchedule = castDraft(action.payload.schedule);
        state.isRecalculationLoading = false;

        if (!state.legEditorSchedule) {
          state.legEditorSchedule = castDraft(action.payload.schedule);
        }

        break;

      case RequestDetailActionTypes.GetOfferScheduleFailure:
        state.isRecalculationLoading = false;
        state.offerScheduleError = action.payload;

        break;

      case RequestDetailActionTypes.GetLegEditorSchedule:
        state.isLegEditorScheduleLoading = true;
        state.legEditorScheduleError = null;

        break;

      case RequestDetailActionTypes.GetLegEditorScheduleSuccess:
        state.legEditorSchedule = castDraft(action.payload.schedule);
        state.isLegEditorScheduleLoading = false;

        break;

      case RequestDetailActionTypes.GetLegEditorScheduleFailure:
        state.legEditorScheduleError = action.payload;
        state.isLegEditorScheduleLoading = false;

        break;

      case RequestDetailActionTypes.GetChat:
        state.isChatLoading = true;
        state.chatError = null;

        break;

      case RequestDetailActionTypes.GetChatSuccess:
        state.isChatLoading = false;
        state.chatMessages = action.payload.messages;

        break;

      case RequestDetailActionTypes.GetChatFailure:
        state.isChatLoading = false;
        state.chatError = action.payload.error;

        break;

      case RequestDetailActionTypes.PostChatMessage:
        state.isPostChatMessageLoading = true;
        state.postChatMessageError = null;

        break;

      case RequestDetailActionTypes.PostChatMessageSuccess:
        state.isPostChatMessageLoading = false;

        break;

      case RequestDetailActionTypes.PostChatMessageFailure:
        state.isPostChatMessageLoading = false;
        state.postChatMessageError = action.payload.error;

        break;

      case RequestDetailActionTypes.GetOfferRelatedCustomRoutes:
        state.isOfferRelatedCustomRoutesDataLoading = true;
        state.offerRelatedCustomRoutesError = null;

        break;

      case RequestDetailActionTypes.GetOfferRelatedCustomRoutesSuccess:
        state.isOfferRelatedCustomRoutesDataLoading = false;
        state.offerRelatedCustomRoutesError = null;
        state.offerRelatedCustomRoutesData = action.payload.data;

        break;

      case RequestDetailActionTypes.GetOfferRelatedCustomRoutesFailure:
        state.isOfferRelatedCustomRoutesDataLoading = false;
        state.offerRelatedCustomRoutesError = action.payload.error;

        break;

      case RequestDetailActionTypes.GetOfferRelatedAirportFees:
        state.isOfferRelatedAirportFeesDataLoading = true;
        state.offerRelatedAirportFeesError = null;

        break;

      case RequestDetailActionTypes.GetOfferRelatedAirportFeesSuccess:
        state.isOfferRelatedAirportFeesDataLoading = false;
        state.offerRelatedAirportFeesError = null;
        state.offerRelatedAirportFeesData = action.payload.data;

        break;

      case RequestDetailActionTypes.GetOfferRelatedAirportFeesFailure:
        state.isOfferRelatedAirportFeesDataLoading = false;
        state.offerRelatedAirportFeesError = action.payload.error;

        break;

      case RequestDetailActionTypes.ResetRecalculationState:
        state.recalculationData = initialOfferDetailState["recalculationData"];
        state.isRecalculationLoading =
          initialOfferDetailState["isRecalculationLoading"];
        state.recalculationError =
          initialOfferDetailState["recalculationError"];

        break;

      case RequestDetailActionTypes.ResetRequestDetailState:
        return initialOfferDetailState;

      case RequestDetailActionTypes.SetOpenOfferId:
        state.openOfferId = action.payload;

        break;

      case RequestDetailActionTypes.IgnoreLegsFromOptimizationFailure:
        state.isIgnoreLegsFromOptimizationLoading = false;
        state.ignoreLegsFromOptimizationData = null;
        state.ignoreLegsFromOptimizationError = action.payload.error;

        break;

      case RequestDetailActionTypes.IgnoreLegsFromOptimizationSuccess:
        state.isIgnoreLegsFromOptimizationLoading = false;
        state.ignoreLegsFromOptimizationData = action.payload.data;
        state.ignoreLegsFromOptimizationError = null;

        break;

      case RequestDetailActionTypes.IgnoreLegsFromOptimization:
        state.isIgnoreLegsFromOptimizationLoading = true;
        state.ignoreLegsFromOptimizationError = null;

        break;

      case RequestDetailActionTypes.ResetIgnoreLegsFromOptimizationState:
        state.isIgnoreLegsFromOptimizationLoading = false;
        state.ignoreLegsFromOptimizationData = null;
        state.ignoreLegsFromOptimizationError = null;

        break;

      default:
        return state;
    }
  },
  initialOfferDetailState,
);

export default RequestsListReducer;
