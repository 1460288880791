import React from 'react'
import ReactMarkdown from 'react-markdown'
import { useTranslation } from 'react-i18next'

import CredentialsLayout, {
  SidebarAction,
} from '@app/components/organisms/CredentialsLayout/CredentialsLayout'

import { assertUnreachable } from '@strafos/common'

enum SidebarActions {
  GO_BACK = 'go-back',
  RELOAD = 'reload',
}

const Error = (): JSX.Element => {
  const { t } = useTranslation()

  const sidebarActions: SidebarAction<SidebarActions>[] = [
    { id: SidebarActions.GO_BACK, label: t('molecules.Error.goBack') },
    { id: SidebarActions.RELOAD, label: t('molecules.Error.reload') },
  ]

  const handleSidebarActionButtonClick = (
    sidebarAction: SidebarAction<SidebarActions>,
  ) => {
    switch (sidebarAction.id) {
      case SidebarActions.GO_BACK:
        return window.history.back()

      case SidebarActions.RELOAD:
        return window.location.reload()

      default:
        assertUnreachable(sidebarAction.id)
    }
  }

  return (
    <CredentialsLayout
      sidebarTitle={t('molecules.Error.sidebarTitle')}
      sidebarActions={sidebarActions}
      onSidebarActionButtonClick={handleSidebarActionButtonClick}
      sidebarContent={
        <ReactMarkdown>{t('molecules.Error.content')}</ReactMarkdown>
      }
    />
  )
}

export default Error
