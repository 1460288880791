import { ActionType } from "typesafe-actions";
import produce, { castDraft } from "immer";

import { CustomRouteDetailDto } from "@strafos/common";
import { CustomRoutesActionTypes } from "@app/store/api/customRoutes/customRoutes.constants";
import { ListStore } from "@app/store/types";
import { GetCustomRoutesFilters } from "@app/utils/api/types";
import * as CustomRoutesActions from "@app/store/api/customRoutes/customRoutes.actions";
import { CustomRoutesOrderBy } from "@strafos/common";

import { DEFAULT_ORDER_DIRECTION, DEFAULT_PAGE_LIMIT } from "@app/constants";

type CustomRoutesAction = ActionType<typeof CustomRoutesActions>;

export type CustomRoutesStore = Omit<
  ListStore<CustomRouteDetailDto, GetCustomRoutesFilters>,
  "orderBy"
> & {
  orderBy: CustomRoutesOrderBy;
  isPatchLoading: boolean;
  isPostLoading: boolean;
  isDeleteLoading: boolean;
};

const initialCustomRoutesState: CustomRoutesStore = {
  error: null,
  isLoading: false,
  data: null,
  total: null,
  page: 1,
  limit: DEFAULT_PAGE_LIMIT,
  orderBy: CustomRoutesOrderBy.CreatedAt,
  orderDirection: DEFAULT_ORDER_DIRECTION,
  filters: {},
  isPatchLoading: false,
  isPostLoading: false,
  isDeleteLoading: false,
};

const CustomRoutesReducer = produce<CustomRoutesStore, [CustomRoutesAction]>(
  (state, action) => {
    switch (action.type) {
      case CustomRoutesActionTypes.GetCustomRoutesDataFirstPage:
        state.isLoading = true;
        state.data = null;
        state.error = null;
        state.total = 0;
        state.limit = DEFAULT_PAGE_LIMIT;
        state.page = 1;

        break;

      case CustomRoutesActionTypes.GetCustomRoutesSuccess:
        // @see https://immerjs.github.io/immer/typescript/#cast-utilities
        state.data ??= [];
        state.data.push(...castDraft(action.payload.data));

        state.isLoading = false;
        state.error = null;
        state.total = action.payload.total;
        state.page = action.payload.page;

        break;

      case CustomRoutesActionTypes.GetCustomRoutesFailure:
        state.isLoading = false;
        state.error = action.payload;

        break;

      case CustomRoutesActionTypes.GetCustomRoutesDataNextPage:
        state.isLoading = true;

        break;

      case CustomRoutesActionTypes.SetCustomRoutesSort:
        if (action.payload.orderBy) {
          state.orderBy = action.payload.orderBy;
        }

        if (action.payload.orderDirection) {
          state.orderDirection = action.payload.orderDirection;
        }

        break;

      case CustomRoutesActionTypes.SetCustomRoutesFilters:
        if (action.payload.filters) {
          state.filters = { ...state.filters, ...action.payload.filters };
        }

        break;

      case CustomRoutesActionTypes.ResetCustomRoutesFilters:
        state.filters = {};

        break;

      case CustomRoutesActionTypes.PostCustomRoute:
        state.isPostLoading = true;

        break;

      case CustomRoutesActionTypes.PostCustomRouteSuccess:
      case CustomRoutesActionTypes.PostCustomRouteFailure:
      case CustomRoutesActionTypes.CancelPostCustomRouteLoading:
        state.isPostLoading = false;

        break;

      case CustomRoutesActionTypes.PatchCustomRoute:
        state.isPatchLoading = true;

        break;

      case CustomRoutesActionTypes.PatchCustomRouteSuccess:
      case CustomRoutesActionTypes.PatchCustomRouteFailure:
        state.isPatchLoading = false;

        break;

      case CustomRoutesActionTypes.DeleteCustomRoute:
        state.isDeleteLoading = true;

        break;

      case CustomRoutesActionTypes.DeleteCustomRouteSuccess:
      case CustomRoutesActionTypes.DeleteCustomRouteFailure:
      case CustomRoutesActionTypes.CancelDeleteCustomRouteLoading:
        state.isDeleteLoading = false;

        break;

      case CustomRoutesActionTypes.SetCustomRoutesData:
        state.data = [];
        state.data.push(...castDraft(action.payload.data));

        state.isLoading = false;
        state.error = null;
        state.total = action.payload.total;
        state.page = action.payload.page;

        break;

      default:
        return state;
    }
  },
  initialCustomRoutesState,
);

export default CustomRoutesReducer;
