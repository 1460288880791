import { ActionType } from "typesafe-actions";
import produce from "immer";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import * as CompanySettingsActions from "@app/store/pages/settings/companySettings/companySettings.actions";
import { CompanySettingsActionTypes } from "@app/store/pages/settings/companySettings/companySettings.constants";
import { CompanyDto } from "@strafos/common";

dayjs.extend(utc);

type CompanySettingsAction = ActionType<typeof CompanySettingsActions>;

export interface CompanySettingsStore {
  isCompanyLoading: boolean;
  companyError: unknown;
  company: CompanyDto | null;

  isPatchCompanyLoading: boolean;
  patchCompanyError: unknown;
}

const initialCompanySettingsState: CompanySettingsStore = {
  isCompanyLoading: false,
  companyError: null,
  company: null,

  patchCompanyError: null,
  isPatchCompanyLoading: false,
};

const CompanySettingsReducer = produce<
  CompanySettingsStore,
  [CompanySettingsAction]
>((state, action) => {
  switch (action.type) {
    case CompanySettingsActionTypes.GetCompany:
      state.isCompanyLoading = true;
      state.companyError = null;

      break;

    case CompanySettingsActionTypes.GetCompanySuccess:
      state.isCompanyLoading = false;
      state.company = action.payload.company;
      state.companyError = null;

      break;

    case CompanySettingsActionTypes.GetCompanyFailure:
      state.isCompanyLoading = false;
      state.companyError = action.payload.error;

      break;

    case CompanySettingsActionTypes.PatchCompany:
      state.isPatchCompanyLoading = true;
      state.patchCompanyError = null;

      break;

    case CompanySettingsActionTypes.PatchCompanySuccess:
      state.isPatchCompanyLoading = false;
      state.patchCompanyError = null;

      break;

    case CompanySettingsActionTypes.PatchCompanyFailure:
      state.isPatchCompanyLoading = false;
      state.patchCompanyError = action.payload.error;

      break;

    default:
      return state;
  }
}, initialCompanySettingsState);

export default CompanySettingsReducer;
