import { ActionType } from "typesafe-actions";
import produce, { castDraft } from "immer";

import { AirportFeesActionTypes } from "@app/store/api/airportFees/airportFees.constants";
import { ListStore } from "@app/store/types";
import { GetAirportFeesFilters } from "@app/utils/api/types";
import * as AirportFeesActions from "@app/store/api/airportFees/airportFees.actions";
import { AirportFeeDto, AirportFeesOrderBy } from "@strafos/common";

import { DEFAULT_ORDER_DIRECTION, DEFAULT_PAGE_LIMIT } from "@app/constants";

type AirportFeesAction = ActionType<typeof AirportFeesActions>;

export type AirportFeesStore = Omit<
  ListStore<AirportFeeDto, GetAirportFeesFilters>,
  "orderBy"
> & {
  orderBy: AirportFeesOrderBy;
  isPatchLoading: boolean;
  isPostLoading: boolean;
  isDeleteLoading: boolean;
};

const initialAirportFeesState: AirportFeesStore = {
  error: null,
  isLoading: false,
  data: null,
  total: null,
  page: 1,
  limit: DEFAULT_PAGE_LIMIT,
  orderBy: AirportFeesOrderBy.CreatedAt,
  orderDirection: DEFAULT_ORDER_DIRECTION,
  filters: {},
  isPatchLoading: false,
  isPostLoading: false,
  isDeleteLoading: false,
};

const AirportFeesReducer = produce<AirportFeesStore, [AirportFeesAction]>(
  (state, action) => {
    switch (action.type) {
      case AirportFeesActionTypes.GetAirportFeesDataFirstPage:
        state.isLoading = true;
        state.data = null;
        state.error = null;
        state.total = 0;
        state.limit = DEFAULT_PAGE_LIMIT;
        state.page = 1;

        break;

      case AirportFeesActionTypes.GetAirportFeesSuccess:
        // @see https://immerjs.github.io/immer/typescript/#cast-utilities
        state.data ??= [];
        state.data.push(...castDraft(action.payload.data));

        state.isLoading = false;
        state.error = null;
        state.total = action.payload.total;
        state.page = action.payload.page;

        break;

      case AirportFeesActionTypes.GetAirportFeesFailure:
        state.isLoading = false;
        state.error = action.payload;

        break;

      case AirportFeesActionTypes.GetAirportFeesDataNextPage:
        state.isLoading = true;

        break;

      case AirportFeesActionTypes.SetAirportFeesSort:
        if (action.payload.orderBy) {
          state.orderBy = action.payload.orderBy;
        }

        if (action.payload.orderDirection) {
          state.orderDirection = action.payload.orderDirection;
        }

        break;

      case AirportFeesActionTypes.SetAirportFeesFilters:
        if (action.payload.filters) {
          state.filters = { ...state.filters, ...action.payload.filters };
        }

        break;

      case AirportFeesActionTypes.ResetAirportFeesFilters:
        state.filters = {};

        break;

      case AirportFeesActionTypes.PostAirportFee:
        state.isPostLoading = true;

        break;

      case AirportFeesActionTypes.PostAirportFeeSuccess:
      case AirportFeesActionTypes.PostAirportFeeFailure:
      case AirportFeesActionTypes.CancelPostAirportFeeLoading:
        state.isPostLoading = false;

        break;

      case AirportFeesActionTypes.PatchAirportFee:
        state.isPatchLoading = true;

        break;

      case AirportFeesActionTypes.PatchAirportFeeSuccess:
      case AirportFeesActionTypes.PatchAirportFeeFailure:
        state.isPatchLoading = false;

        break;

      case AirportFeesActionTypes.DeleteAirportFee:
        state.isDeleteLoading = true;

        break;

      case AirportFeesActionTypes.DeleteAirportFeeSuccess:
      case AirportFeesActionTypes.DeleteAirportFeeFailure:
      case AirportFeesActionTypes.CancelDeleteAirportFeeLoading:
        state.isDeleteLoading = false;

        break;

      case AirportFeesActionTypes.SetAirportFeesData:
        state.data = [];
        state.data.push(...castDraft(action.payload.data));

        state.isLoading = false;
        state.error = null;
        state.total = action.payload.total;
        state.page = action.payload.page;

        break;

      default:
        return state;
    }
  },
  initialAirportFeesState,
);

export default AirportFeesReducer;
