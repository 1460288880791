import { action } from "typesafe-actions";

import { UserInfoActionTypes } from "@app/store/core/userInfo/userInfo.constants";
import { UserDto } from "@strafos/common";

import {
  UpdatePasswordDto,
  UpdateUserDto,
  UpdateUserDisplaySettingsDto,
} from "@app/utils/api/types";

export const getMyUserInfoAction = () =>
  action(UserInfoActionTypes.GetMyUserInfo);

export const getMyUserInfoSuccessAction = (response: UserDto) =>
  action(UserInfoActionTypes.GetMyUserInfoSuccess, response);

export const getMyUserInfoFailureAction = (error: unknown) =>
  action(UserInfoActionTypes.GetMyUserInfoFailure, error);

export const getUserInfoAction = (id: number) =>
  action(UserInfoActionTypes.GetUserInfo, id);

export const getUserInfoSuccessAction = (response: UserDto) =>
  action(UserInfoActionTypes.GetUserInfoSuccess, response);

export const getUserInfoFailureAction = (error: unknown) =>
  action(UserInfoActionTypes.GetUserInfoFailure, error);

export const patchUserAction = (id: number, partialUser: UpdateUserDto) =>
  action(UserInfoActionTypes.PatchUser, { id, partialUser });

export const patchUserSuccessAction = (response: UserDto) =>
  action(UserInfoActionTypes.PatchUserSuccess, response);

export const patchUserFailureAction = (error: unknown) =>
  action(UserInfoActionTypes.PatchUserFailure, error);

export const patchMyUserAction = (
  partialUser: UpdateUserDto | UpdateUserDisplaySettingsDto,
) => action(UserInfoActionTypes.PatchMyUser, partialUser);

export const patchMyUserSuccessAction = (response: UserDto) =>
  action(UserInfoActionTypes.PatchMyUserSuccess, response);

export const patchMyUserFailureAction = (error: unknown) =>
  action(UserInfoActionTypes.PatchMyUserFailure, error);

export const putMyPasswordAction = (updatePasswordDto: UpdatePasswordDto) =>
  action(UserInfoActionTypes.PutMyPassword, updatePasswordDto, {
    deferred: true,
  });

export const putMyPasswordSuccessAction = () =>
  action(UserInfoActionTypes.PutMyPasswordSuccess);

export const putMyPasswordFailureAction = (error: unknown) =>
  action(UserInfoActionTypes.PutMyPasswordFailure, error);

export const resetUserInfoAction = () =>
  action(UserInfoActionTypes.ResetUserInfoState);
