import { ActionType } from "typesafe-actions";
import produce, { castDraft } from "immer";

import { RequestsReportsActionTypes } from "@app/store/pages/reports/requestsReports/requestsReports.constants";
import { BaseDataStore } from "@app/store/types";

import * as requestsReportsActions from "@app/store/pages/reports/requestsReports/requestsReports.actions";

import { ReportsSearchParams, RequestReportsDto } from "@strafos/common";

type RequestsReportsAction = ActionType<typeof requestsReportsActions>;

export interface RequestsReportsStore extends BaseDataStore<RequestReportsDto> {
  filters: ReportsSearchParams;
}

const initialRequestsReportsState: RequestsReportsStore = {
  error: null,
  isLoading: false,
  data: null,
  filters: {},
};

const requestsReportsReducer = produce<
  RequestsReportsStore,
  [RequestsReportsAction]
>((state, action) => {
  switch (action.type) {
    case RequestsReportsActionTypes.GetRequestsReports:
      state.isLoading = true;
      state.data = null;
      state.error = null;

      break;

    case RequestsReportsActionTypes.GetRequestsReportsSuccess:
      state.isLoading = false;
      state.data = castDraft(action.payload);
      state.error = null;

      break;

    case RequestsReportsActionTypes.GetRequestsReportsFailure:
      state.isLoading = false;
      state.error = action.payload.error;
      state.data = null;

      break;

    case RequestsReportsActionTypes.SetRequestsReportsFilters:
      if (action.payload.filters) {
        state.filters = { ...state.filters, ...action.payload.filters };
      }

      break;

    case RequestsReportsActionTypes.ResetRequestsReportsFilters:
      state.filters = {};

      break;

    default:
      return state;
  }
}, initialRequestsReportsState);

export default requestsReportsReducer;
