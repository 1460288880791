// withTranslation

import {
  call,
  CallEffect,
  delay,
  put,
  PutEffect,
  race,
  select,
  take,
  takeLatest,
} from "redux-saga/effects";

import { AxiosResponse } from "axios";

import { api } from "@app/utils/api/api";

import * as actions from "@app/store/pages/requests/createRequest/createRequest.actions";
import * as requestListActions from "@app/store/pages/requests/requestList/requestList.actions";
import * as requestDetailActions from "@app/store/pages/requests/requestDetail/requestDetail.actions";

import { postComputationFailure } from "@app/store/pages/requests/createRequest/createRequest.actions";
import { CreateRequestActionTypes } from "@app/store/pages/requests/createRequest/createRequest.constants";
import { ComputationStatuses, ComputationTypes } from "@strafos/common";
import { getErrorMessage } from "@app/utils/errorHandling";
import { addNotificationAction } from "@app/store/ui/notifications/notifications.actions";
import { selectCreateRequestComputationId } from "@app/store/pages/requests/createRequest/createRequest.selectors";
import { selectSelectedOperator } from "@app/store/core/userOperators/userOperators.selectors";

import {
  OffersComputationResultDto,
  CreateResourceDto,
  CreateOffersFromComputationDto,
} from "@strafos/common";

const COMPUTATION_POLLING_DELAY = 1000;

function* watchComputation(
  computationId: number,
): Generator<
  CallEffect | PutEffect,
  void,
  AxiosResponse<OffersComputationResultDto>
> {
  try {
    const { data }: AxiosResponse<OffersComputationResultDto> = yield call(
      api.getComputation,
      computationId,
    );

    if (data.status === ComputationStatuses.Finished) {
      yield put(actions.watchComputationSuccess(computationId, data));
    }

    yield delay(COMPUTATION_POLLING_DELAY);

    yield call(watchComputation, computationId);
  } catch (error) {
    const errorMessageKey = getErrorMessage(error, {
      // t('errors.general.unauthorized')
      401: "errors.general.unauthorized",
      // t('errors.getComputation.default')
      default: "errors.getComputation.default",
    });

    if (errorMessageKey) {
      yield put(
        addNotificationAction({
          i18nextKey: errorMessageKey,
          type: "error",
        }),
      );
    }

    yield put(actions.watchComputationFailure(error));
  }
}

function* triggerComputationPolling(
  action: ReturnType<typeof actions.triggerComputationPolling>,
) {
  const operator = selectSelectedOperator(yield select());

  if (!operator) {
    throw new Error("Operator is required");
  }

  try {
    // TODO select operator id from redux
    const { data }: AxiosResponse<CreateResourceDto> = yield call(
      api.createComputation,
      {
        ...action.payload,
        type: ComputationTypes.Offer,
      },
    );

    yield put(actions.watchComputation(data.id));
  } catch (error) {
    const errorMessageKey = getErrorMessage(error, {
      // t('errors.general.unauthorized')
      401: "errors.general.unauthorized",
      // t('errors.getComputation.default')
      default: "errors.postComputation.default",
    });

    if (errorMessageKey) {
      yield put(
        addNotificationAction({
          i18nextKey: errorMessageKey,
          type: "error",
        }),
      );
    }

    yield put(postComputationFailure(error));
  }
}

function* watchComputationTrigger() {
  while (true) {
    const action: ReturnType<typeof actions.watchComputation> = yield take(
      CreateRequestActionTypes.WatchComputation,
    );

    yield race([
      call(watchComputation, action.payload),
      take(CreateRequestActionTypes.WatchComputationSuccess),
      take(CreateRequestActionTypes.WatchComputationFailure),
      take(CreateRequestActionTypes.StopComputationPolling),
    ]);
  }
}

function* createOffersFromComputationSaga(
  action: ReturnType<typeof actions.postCreateOffersFromComputation>,
) {
  try {
    const computationId = selectCreateRequestComputationId(yield select());

    if (!computationId) {
      throw new Error("No computation id stored");
    }

    const params: CreateOffersFromComputationDto = {
      computation_id: computationId,
      ...action.payload,
    };

    const {
      data: { request_id },
    } = yield call(api.createOffersFromComputation, params);

    yield put(
      addNotificationAction({
        // t('messages.createOffersFromComputation.success')
        i18nextKey: "messages.createOffersFromComputation.success",
        type: "success",
      }),
    );

    if (action.payload.request_id) {
      yield put(requestListActions.reloadRequestsListAction());

      yield put(requestDetailActions.reloadRequestDetailAction());
    } else {
      yield put(requestListActions.getRequestsListDataFirstPageAction());

      yield put(requestListActions.setOpenRequestIdAction(request_id));
    }

    yield put(actions.postCreateOffersFromComputationSuccess());
  } catch (error) {
    // @todo Global error trap ???
    // @todo Omit in production
    console.error(error);

    const errorMessageKey = getErrorMessage(error, {
      // t('errors.general.unauthorized')
      401: "errors.general.unauthorized",
      // t('errors.createOffersFromComputation.default')
      default: "errors.createOffersFromComputation.default",
    });

    if (errorMessageKey) {
      yield put(
        addNotificationAction({
          i18nextKey: errorMessageKey,
          type: "error",
        }),
      );
    }

    yield put(actions.postCreateOffersFromComputationFailure(error));
  }
}

export default function* watchCreateRequestSaga(): Generator {
  yield takeLatest(
    CreateRequestActionTypes.TriggerComputationPolling,
    triggerComputationPolling,
  );

  yield takeLatest(
    CreateRequestActionTypes.PostCreateOffersFromComputation,
    createOffersFromComputationSaga,
  );

  yield watchComputationTrigger();
}
