// withTranslation

import { call, put, select, takeLatest } from "redux-saga/effects";
import { navigate } from "@reach/router";

import { api } from "@app/utils/api/api";
import { getErrorMessage } from "@app/utils/errorHandling";
import { addNotificationAction } from "@app/store/ui/notifications/notifications.actions";

import { Routes } from "@strafos/common";
import { CreateAircraftActionTypes } from "@app/store/pages/createAircraft/createAircraft/createAircraft.constants";
import { AircraftDetailDto } from "@strafos/common";
import { selectCreateAircraftPartialData } from "@app/store/pages/createAircraft/createAircraft/createAircraft.selectors";
import * as actions from "@app/store/pages/createAircraft/createAircraft/createAircraft.actions";

import {
  generalSpecificationDataSchema,
  performanceDataSchema,
  pricingDataSchema,
} from "@app/components/pages/CreateAircraft/utils";

function* postAircraftSaga() {
  try {
    const aircraftData = selectCreateAircraftPartialData(yield select());

    generalSpecificationDataSchema.validateSync(aircraftData);
    performanceDataSchema.validateSync(aircraftData);
    pricingDataSchema.validateSync(aircraftData);

    const params = {
      ...aircraftData,
    } as AircraftDetailDto;

    yield call(api.createAircraft, params);

    yield put(
      addNotificationAction({
        // t('messages.postAircraft.success')
        i18nextKey: "messages.postAircraft.success",
        type: "success",
      }),
    );

    yield put(actions.postAircraftSuccessAction());

    navigate(Routes.Fleet);
  } catch (error) {
    console.error(error);

    const errorMessageKey = getErrorMessage(error, {
      // t('errors.general.unauthorized')
      401: "errors.general.unauthorized",
      409: error.response.data.message,
      // t('errors.postAircraft.default')
      default: "errors.postAircraft.default",
    });

    if (errorMessageKey) {
      yield put(
        addNotificationAction({
          i18nextKey: errorMessageKey,
          type: "error",
        }),
      );
    }

    yield put(actions.postAircraftFailureAction(error));
  }
}

export default function* watchCreateAircraftSaga(): Generator {
  yield takeLatest(CreateAircraftActionTypes.PostAircraft, postAircraftSaga);
}
